import Image from 'next/legacy/image';
import {
  Modal,
  ModalProps,
  Group,
  Button,
  Text,
  Box,
  ActionIcon,
  useMantineTheme,
} from '@mantine/core';
import { PebbleLogoDarkBlue } from 'components/ui';
import classes from './CreateAccountModal.module.scss';
import MotherAndChild from '../../../public/mobile_account_modal.png';
import { trackAction, Actions } from 'utils/amplitude';
import { useBasket } from 'context/BasketContext';
import { XCircle } from '@phosphor-icons/react';

interface ICreateAccountModal extends ModalProps {
  onClose(): void;
  handleCreateAccountModalClick(routeName: string): void;
}

const CreateAccountModal: React.FC<ICreateAccountModal> = ({
  onClose,
  handleCreateAccountModalClick,
  ...modalProps
}) => {
  const guest = 'guest';
  const login = 'login';
  const register = 'register';

  const { setShowCreateAccountModal, setShowTicketTypesModal } = useBasket();

  const theme = useMantineTheme();

  return (
    <Modal
      {...modalProps}
      size={875}
      classNames={{
        content: classes.modal,
        body: classes.modalBody,
      }}
      padding={0}
      onClose={onClose}
      centered
      zIndex={202}
    >
      <Group align="stretch" className={classes.container}>
        <ActionIcon
          onClick={onClose}
          className={classes.closeButton}
          variant="transparent"
          h="auto"
          w="auto"
        >
          <XCircle size={38} weight="fill" color={theme.colors.blue[8]} />
        </ActionIcon>
        <div className={classes.imageWrapper}>
          <div className={classes.womanAndChild}>
            <Image src={MotherAndChild} />
          </div>
        </div>
        <div className={classes.modalContent}>
          <Group>
            <Button
              className={classes.guestCheckoutButton}
              aria-label="guest-checkout-button"
              fullWidth
              size="lg"
              radius="xl"
              onClick={() => {
                trackAction(Actions.GUEST_CHECKOUT_SELECTED);

                setShowTicketTypesModal(false);

                setShowCreateAccountModal(false);

                handleCreateAccountModalClick(guest);
              }}
            >
              Checkout as guest
            </Button>
            <Text className={classes.text}>or</Text>
            <Button
              className={classes.registerButton}
              variant="outline"
              fullWidth
              size="lg"
              radius="xl"
              onClick={() => {
                trackAction(Actions.CREATE_AN_ACCOUNT);
                handleCreateAccountModalClick(register);
              }}
            >
              Create an account
            </Button>
          </Group>
          <Box mt="lg">
            <Text>Already have an account?</Text>

            <Button
              aria-label="login-button"
              className={classes.loginLink}
              onClick={() => {
                trackAction(Actions.MARKETPLACE_LOGIN_SELECTED);
                handleCreateAccountModalClick(login);
              }}
            >
              Log in
            </Button>
          </Box>
        </div>
        <div className={classes.logoWrapper}>{<PebbleLogoDarkBlue />}</div>
      </Group>
    </Modal>
  );
};

export default CreateAccountModal;
