import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  CloseButton,
  Divider,
  Drawer,
  Flex,
  Modal,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Title,
  useMantineTheme,
} from '@mantine/core';
import classes from './AddOnsStep.module.scss';
import { AddOnFormValues, AttendeeAddonInputType, PerSessionAddonList } from 'interfaces';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import SessionSelectContent from './SessionSelectContent/SessionSelectContent';
import {
  getAddOnInput,
  getExistingSelectedSessions,
  initialSessions,
  isAnySessionInEditMode,
  noSessionsAreChecked,
  totalAmountAdded,
  totalPrice,
} from './AddOnsStep.utils';
import { useForm } from '@mantine/form';
import { Pebbles } from '@ui';
import { formatPenceToPounds } from 'utils/formatPrice';
import { Actions, trackAction } from 'utils/amplitude';
import { BookingAddons } from './BookingAddon';
import {
  Person,
  Plus,
  Trash,
  X,
  CheckCircle,
  CaretDown,
  PencilSimple,
} from '@phosphor-icons/react';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import { StepperEnum } from 'enums';
import { executeScroll } from '../CheckoutDetailsUtils';

interface IAddOnsStepProps {
  updateAttendeesLoading: boolean;
  hideEndTimes?: boolean;
}

type SelectedSessionInfo = {
  addOn: PerSessionAddonList;
  addOnIndex: number;
  sessionIndex: number;
};

const AddOnsStep: React.FC<IAddOnsStepProps> = ({
  updateAttendeesLoading,
  hideEndTimes = false,
}) => {
  const { basket, setActiveStep, basketSelectAddonsMutation, scrollRef } =
    useCheckoutDetailsContext();
  const { addons: addOns } = basket;

  const handleUpdateAddOns = async (inputData: AttendeeAddonInputType[] | undefined) => {
    await basketSelectAddonsMutation({
      variables: {
        input: {
          id: basket.id,
          addons: inputData,
        },
      },
    });
  };

  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const [opened, { open, close }] = useDisclosure(false);

  const getActiveItem = useMemo(() => {
    return addOns?.[0]?.attendee.id ? addOns?.[0]?.attendee.id : null;
  }, [addOns]);

  const [activeItem, setActiveItem] = useState<string | null>(getActiveItem);

  useEffect(() => {
    setActiveItem(getActiveItem);
  }, [getActiveItem]);

  const [activeSessionInfo, setActiveSessionInfo] = useState<SelectedSessionInfo | null>(null);

  const filterSessionsWithoutAvailableSessions = (
    sessions: PerSessionAddonList[],
  ): PerSessionAddonList[] => {
    return sessions.filter(
      (sessionAddOn) =>
        sessionAddOn.addonOption.availableSessions &&
        sessionAddOn.addonOption.availableSessions.length > 0,
    );
  };

  const initialAddOnValues = addOns?.map((addOn) => {
    return {
      attendeeId: addOn.attendee.id,
      perSession: filterSessionsWithoutAvailableSessions(addOn.perSession).map((session) => {
        return {
          addonOptionId: session.addonOption.id,
          price: session.addonOption.price,
          selectedSessions:
            session.selectedSessions.length > 0
              ? getExistingSelectedSessions(session)
              : initialSessions(session),
          note: session.note,
        };
      }),
      perBooking: addOn.perBooking?.map((booking) => {
        return {
          addonOptionId: booking.addonOption.id,
          price: booking.addonOption.price,
          quantity: booking.quantity,
          note: booking.note,
        };
      }),
    };
  });

  const addOnsForm = useForm<AddOnFormValues>({
    initialValues: {
      addOns: initialAddOnValues || [],
    },
  });

  const openSessionDetails = (
    session: PerSessionAddonList,
    addOnIndex: number,
    sessionIndex: number,
  ) => {
    setActiveSessionInfo({
      addOn: session,
      addOnIndex,
      sessionIndex,
    });

    open();
  };

  const closeSessionDetails = () => {
    setActiveSessionInfo(null);
    close();
  };

  const getTitle = (session?: PerSessionAddonList) => {
    if (!session) {
      return null;
    }

    const isFreeAddon = session.addonOption.price === 0;

    return (
      <Box ml={isMobile ? 0 : 'md'}>
        <Text c={theme.colors.blue[8]} fw={700} size="18px" mb="4px" mt={isMobile ? 'md' : 'lg'}>
          {session.addonOption.name}
        </Text>
        <Text c={theme.colors.gray[6]} fw={600} size="12px" mb="md">
          {session.addonOption.description}
        </Text>
        {isFreeAddon ? (
          <Text c={theme.colors.blue[8]} fw={600} size="14px" mb="md">
            <Text c={theme.colors.pink[6]} component="span">
              Free
            </Text>{' '}
            / session
          </Text>
        ) : (
          <Text c={theme.colors.blue[8]} fw={600} size="14px" mb="md">
            {formatPenceToPounds(session.addonOption.price)} / session
          </Text>
        )}
      </Box>
    );
  };

  const getAddOnDetails = (attendeeId: string) => {
    // Find attendee
    const findAttendee = addOnsForm.values.addOns?.find((s) => s.attendeeId === attendeeId);

    if (typeof findAttendee === 'undefined') {
      return [];
    }

    const allAddons = [...findAttendee.perSession, ...findAttendee.perBooking];

    // Find addOn ids which have any some checked sessions
    const findOptionIdsAdded = allAddons.flatMap((s) => {
      const quantity = 'quantity' in s ? s.quantity : 0;
      if (quantity > 0) {
        return {
          addonOptionId: s.addonOptionId,
          count: quantity,
        };
      }

      const selectedSessions = 'selectedSessions' in s ? s.selectedSessions : [];
      const foundChecked = selectedSessions.some((selectedSession) => selectedSession.checked);
      if (foundChecked) {
        // Return both the ID and the count of selected sessions for this addOn
        return {
          addonOptionId: s.addonOptionId,
          count: selectedSessions.filter((selectedSession) => selectedSession.checked).length,
        };
      }
      return [];
    });

    // Map addOn Ids to addOn names
    const addonDetails = findOptionIdsAdded
      ?.map(({ addonOptionId, count }) => {
        const addonName = addOns
          ?.flatMap((addOn) => [...(addOn.perSession ?? []), ...(addOn.perBooking ?? [])])
          .find((item) => item.addonOption.id === addonOptionId)?.addonOption.name;

        return { name: addonName, count };
      })
      .filter(({ name }) => name);

    return addonDetails || [];
  };

  const resetSelectedSessions = async (addOnIndex: number, sessionIndex: number) => {
    const updatedAddOns = addOnsForm.values.addOns?.map((addOn, aIndex) => {
      if (aIndex === addOnIndex) {
        const updatedPerSession = addOn.perSession.map((session, sIndex) => {
          if (sIndex === sessionIndex) {
            // Reset all selectedSessions to checked: false
            const updatedSelectedSessions = session.selectedSessions.map((selectedSession) => ({
              ...selectedSession,
              checked: false,
            }));
            return { ...session, selectedSessions: updatedSelectedSessions };
          }
          return session;
        });
        return { ...addOn, perSession: updatedPerSession };
      }
      return addOn;
    });

    addOnsForm.setValues((prevValues) => ({
      ...prevValues,
      addOns: updatedAddOns,
    }));
  };

  const addToOrder = async (addOnIndex: number, sessionIndex: number) => {
    const updateValues = addOnsForm?.values?.addOns?.[addOnIndex].perSession[
      sessionIndex
    ].selectedSessions.map((s) => ({ ...s, checked: true }));

    const updatedAddOnsFormCopy = addOnsForm.values.addOns.map((addOn, copyAddOnIndex) =>
      copyAddOnIndex !== addOnIndex
        ? addOn
        : {
            ...addOn,
            perSession: addOn.perSession.map((session, copySessionIndex) =>
              copySessionIndex !== sessionIndex
                ? session
                : {
                    ...session,
                    selectedSessions: updateValues,
                  },
            ),
          },
    );

    addOnsForm.setFieldValue(
      `addOns.${addOnIndex}.perSession.${sessionIndex}.selectedSessions`,
      updateValues,
    );

    const inputData = getAddOnInput(updatedAddOnsFormCopy);

    await handleUpdateAddOns(inputData);
  };

  const handleNextClick = async () => {
    const inputData = getAddOnInput(addOnsForm.values.addOns);
    await handleUpdateAddOns(inputData);
    executeScroll(scrollRef);
    setActiveStep(StepperEnum.REVIEW);
  };

  if (updateAttendeesLoading || addOns?.length === 0) {
    return (
      <Center style={{ height: '60vh' }}>
        <Pebbles />
      </Center>
    );
  }

  return (
    <Box className={classes.addOnsStepWrapper}>
      <Flex align="center" mb="16px" ml="sm">
        <Title order={3} className={classes.title}>
          Add-Ons
        </Title>
        <Text fw={400} ml="xs" className={classes.optionalTitle}>
          (optional)
        </Text>
      </Flex>

      <Accordion
        mb="md"
        value={activeItem}
        onChange={(value) => setActiveItem(value as string)}
        chevron={<CaretDown weight="bold" size={15} color={theme.colors.blue[8]} />}
        classNames={{
          item: classes.accordionItem,
          content: classes.accordionContent,
          control: classes.accordionControl,
          chevron: classes.accordionChevron,
        }}
      >
        {addOns?.map((addOn, addOnIndex) => {
          return (
            <Accordion.Item
              key={`${addOn.attendee.id}-${addOn.attendee.birthDate}`}
              value={addOn.attendee.id}
            >
              <Accordion.Control
                icon={<Person size={22} weight="fill" color={theme.colors.blue[5]} />}
              >
                <Stack gap={0}>
                  <Flex gap="md" align="center" c={theme.colors.blue[8]}>
                    {addOn.attendee.fullName}
                    {isAnySessionInEditMode(addOn, addOnsForm.values.addOns) && (
                      <ThemeIcon variant="transparent">
                        <CheckCircle weight="fill" color={theme.colors.lime[8]} size={32} />
                      </ThemeIcon>
                    )}
                  </Flex>

                  <Flex>
                    {addOn.attendee.id !== activeItem &&
                      getAddOnDetails(addOn.attendee.id).map((extra) => (
                        <span className={classes.addOnName} key={extra.name}>
                          {extra.name} x{extra.count}
                        </span>
                      ))}
                  </Flex>
                </Stack>
              </Accordion.Control>

              <Accordion.Panel>
                {filterSessionsWithoutAvailableSessions(addOn.perSession).map(
                  (session, sessionIndex) => {
                    const addOnId = session.addonOption.id;
                    const isEditMode = !noSessionsAreChecked(
                      addOnId,
                      addOn.attendee.id,
                      addOnsForm.values.addOns,
                    );

                    const isSingleSessionAddOn =
                      session.addonOption.availableSessions?.length === 1;
                    const isFreeAddOn = session.addonOption.price === 0;
                    const totalAddOnPrice =
                      totalPrice(addOnIndex, sessionIndex, session, addOnsForm.values.addOns) ?? 0;
                    const totalAddOnAmount =
                      totalAmountAdded(addOnIndex, sessionIndex, addOnsForm.values.addOns) ?? 0;

                    return (
                      <Fragment key={session.addonOption.id}>
                        <Card className={classes.addOnCard}>
                          <Flex justify="space-between" align="center">
                            <Text size="14px" fw={700} c={theme.colors.blue[8]} w={'80%'}>
                              {session.addonOption.name}
                            </Text>
                            {isEditMode && !isSingleSessionAddOn && (
                              <Flex align="center">
                                <Button
                                  onClick={() => {
                                    openSessionDetails(session, addOnIndex, sessionIndex);
                                    trackAction(Actions.EDIT_ADDON, { addon: 'Session addon' });
                                  }}
                                  mr="md"
                                  leftSection={<PencilSimple size={18} weight="fill" />}
                                  classNames={{
                                    root: classes.addButton,
                                    section: classes.leftSection,
                                  }}
                                >
                                  Edit
                                </Button>
                                <ActionIcon
                                  title="delete add on"
                                  onClick={() => {
                                    resetSelectedSessions(addOnIndex, sessionIndex);
                                    trackAction(Actions.DELETE_ADDON, { addon: 'Session addon' });
                                  }}
                                  variant="transparent"
                                >
                                  <Trash weight="fill" color={theme.colors.red[8]} size={18} />
                                </ActionIcon>
                              </Flex>
                            )}
                            {!isEditMode && !isSingleSessionAddOn && (
                              <Button
                                onClick={() => {
                                  if (!isSingleSessionAddOn) {
                                    openSessionDetails(session, addOnIndex, sessionIndex);
                                  }
                                  trackAction(Actions.ADD_ADDON);
                                }}
                                leftSection={<Plus size={18} weight="bold" />}
                                classNames={{
                                  root: classes.addButton,
                                  section: classes.leftSection,
                                }}
                              >
                                Add
                              </Button>
                            )}
                            {isSingleSessionAddOn && !isEditMode && (
                              <Button
                                onClick={() => {
                                  addToOrder(addOnIndex, sessionIndex);
                                  trackAction(Actions.ADD_ADDON, { addon: 'Session addon' });
                                }}
                                leftSection={<Plus size={18} weight="bold" />}
                                classNames={{
                                  root: classes.addButton,
                                  section: classes.leftSection,
                                }}
                              >
                                Add
                              </Button>
                            )}
                            {isSingleSessionAddOn && isEditMode && (
                              <ActionIcon
                                title="delete add on"
                                onClick={() => {
                                  resetSelectedSessions(addOnIndex, sessionIndex);
                                  trackAction(Actions.DELETE_ADDON, { addon: 'Session addon' });
                                }}
                                variant="transparent"
                              >
                                <Trash weight="fill" color={theme.colors.red[8]} size={18} />
                              </ActionIcon>
                            )}
                          </Flex>
                          <Text className={classes.description}>
                            {session.addonOption.description}
                          </Text>
                          <Flex justify="space-between" align="center">
                            <Text mt="xs" className={classes.price} fw={700}>
                              {isFreeAddOn ? (
                                <Text component="span" size="sm" fw={700} c={theme.colors.pink[6]}>
                                  Free
                                </Text>
                              ) : (
                                formatPenceToPounds(session.addonOption.price)
                              )}{' '}
                              / session
                              {totalAddOnAmount > 0 && (
                                <Text size="sm" fw={700} component="span">
                                  {` x ${totalAddOnAmount}`}
                                </Text>
                              )}
                            </Text>
                            {isEditMode && totalAddOnPrice > 0 && (
                              <Text size="14px" fw={700} c={theme.colors.blue[8]} mt="xs">
                                Total {formatPenceToPounds(totalAddOnPrice)}
                              </Text>
                            )}
                          </Flex>
                          {isEditMode && (
                            <>
                              <Divider mt="md" variant="dashed" />
                              <Textarea
                                classNames={{ input: classes.textAreaInput }}
                                radius="8px"
                                mt="sm"
                                mb="4px"
                                autosize={true}
                                maxRows={2}
                                maxLength={65}
                                onBlur={() =>
                                  trackAction(Actions.ADDON_ORDER_NOTES, { addon: 'Session addon' })
                                }
                                placeholder="Order notes (optional)"
                                {...addOnsForm.getInputProps(
                                  `addOns.${addOnIndex}.perSession.${sessionIndex}.note`,
                                )}
                              />
                            </>
                          )}
                        </Card>
                      </Fragment>
                    );
                  },
                )}
                {addOn?.perBooking && addOn.perBooking.length > 0 && (
                  <BookingAddons
                    addOn={addOn}
                    addOnIndex={addOnIndex}
                    addOnsForm={addOnsForm}
                    handleUpdateAddOns={handleUpdateAddOns}
                  />
                )}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
      {isMobile ? (
        <Drawer
          title={getTitle(activeSessionInfo?.addOn)}
          position="bottom"
          size="75%"
          opened={opened}
          onClose={() => {
            close();
          }}
          classNames={{
            content: classes.content,
            header: classes.header,
            close: classes.close,
            body: classes.body,
          }}
        >
          {activeSessionInfo && (
            <SessionSelectContent
              session={activeSessionInfo.addOn}
              addOnIndex={activeSessionInfo.addOnIndex}
              sessionIndex={activeSessionInfo.sessionIndex}
              addOnsForm={addOnsForm}
              handleUpdateAddOns={handleUpdateAddOns}
              closeSessionDetails={closeSessionDetails}
              hideEndTimes={hideEndTimes}
            />
          )}
        </Drawer>
      ) : (
        <Modal
          title={getTitle(activeSessionInfo?.addOn)}
          opened={opened}
          onClose={() => {
            close();
          }}
          classNames={{
            content: classes.content,
            header: classes.header,
            close: classes.close,
            body: classes.body,
          }}
          centered
          size={501}
          withCloseButton={false}
        >
          <CloseButton
            size="md"
            icon={<X size={16} weight="bold" />}
            className={classes.closeIcon}
            pos="absolute"
            top={isMobile ? 18 : 16}
            right={isMobile ? 16 : 16}
            onClick={close}
          />
          {activeSessionInfo && (
            <SessionSelectContent
              session={activeSessionInfo.addOn}
              addOnIndex={activeSessionInfo.addOnIndex}
              sessionIndex={activeSessionInfo.sessionIndex}
              addOnsForm={addOnsForm}
              handleUpdateAddOns={handleUpdateAddOns}
              closeSessionDetails={closeSessionDetails}
              hideEndTimes={hideEndTimes}
            />
          )}
        </Modal>
      )}
      <Box className={classes.nextButtonWrapper}>
        <Button onClick={handleNextClick} className={classes.nextButton} fullWidth>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default AddOnsStep;
