import { AnonymisedBookingTicketType } from 'types';

export const calculateTickets = (bookedTickets: AnonymisedBookingTicketType[]) => {
  if (bookedTickets.length <= 0) {
    return '';
  }

  const ticketTotals = bookedTickets.reduce((acc: Record<string, number>, item) => {
    const name = item.ticket.name;

    if (acc[name] === undefined) {
      // If no ticket name found add name with a quantity of 1
      acc[name] = 1;
    } else {
      // Update quantity of ticket name
      acc[name] += 1;
    }

    return acc;
  }, {});

  const ticketsString = Object.entries(ticketTotals)
    .map(([key, value]) => {
      return `${value} x ${key}`;
    })
    .join('\n');

  return ticketsString;
};

export const calculateAddons = (bookedTickets: AnonymisedBookingTicketType[]) => {
  if (bookedTickets.length === 0) {
    return '';
  }

  // Use reducer to accumulate addon quantities
  const addonTotals = bookedTickets.reduce((acc: Record<string, number>, item) => {
    // Iterate over each session of the ticket
    item.sessions.forEach((session) => {
      // Iterate over addons of each session
      session?.addons.forEach((addon) => {
        const name = addon.name;
        // Initialize quantity count for addon if not present
        acc[name] = (acc[name] || 0) + 1;
      });
    });
    return acc;
  }, {});

  const bookingAddonTotals = bookedTickets?.reduce((acc: Record<string, number>, item) => {
    if (!item?.bookingAddons?.length) {
      return acc; // Return acc if bookingAddons doesn't exist or has no entries
    }
    // Iterate over each session of the ticket
    item.bookingAddons.forEach((addon) => {
      const name = addon.name;
      // Initialize quantity count for addon if not present
      acc[name] = (acc[name] || 0) + (addon.quantity ?? 1);
    });
    return acc;
  }, {});

  // Convert addonTotals to a string representation
  const addonsString = Object.entries({ ...addonTotals, ...bookingAddonTotals })
    .map(([addonName, quantity]) => `${quantity} x ${addonName}`)
    .join('\n');

  return addonsString;
};
