import { Image, Box, Text, Group, useMantineTheme, Badge } from '@mantine/core';
import { Activity, Product } from 'types';
import { PreCheckoutBasketTicket } from 'interfaces';
import { ActivityTypeEnum } from 'enums';
import { CloudinaryImage } from 'components/ui';
import SessionDateList from 'components/ActivityDetails/TimesAndAvailability/SessionDateList/SessionDateList';
import classes from './ActivityPreviewCard.module.scss';
import {
  getBookedDatesList,
  getBookedSubscriptionInfo,
} from 'components/CheckoutDetails/CheckoutDetailsUtils';

interface IActivityPreviewCardProps {
  activity?: Activity;
  product?: Product;
  tickets?: PreCheckoutBasketTicket[];
  isWaitlist?: boolean;
}

const ActivityPreviewCard: React.FC<IActivityPreviewCardProps> = ({
  activity,
  product,
  tickets,
  isWaitlist = false,
}) => {
  const theme = useMantineTheme();

  const isSubscription = activity?.activityType === ActivityTypeEnum.SUBSCRIPTION;

  const dates = getBookedDatesList(product, tickets);

  const subscriptionInfo = isSubscription ? getBookedSubscriptionInfo(product) : null;

  return (
    <>
      <div className={classes.card}>
        <section className={classes.cardHeader} aria-label="activity header">
          <Group className={classes.cardHeaderInner}>
            <div className={classes.imgContainer}>
              {activity?.cloudinaryImageId ? (
                <CloudinaryImage
                  id={activity.cloudinaryImageId}
                  alt={`${activity?.name} - ${activity?.supplier?.name}`}
                  width={200}
                  height={200}
                />
              ) : (
                <Image
                  src="../../pebble_placeholder_image.png"
                  width={80}
                  height={80}
                  fit="cover"
                />
              )}
            </div>
            <Box className={classes.cardTitles}>
              <Text
                pb={2}
                lineClamp={2}
                component="h2"
                style={{
                  textAlign: 'left',
                  fontWeight: 700,
                }}
              >
                {activity?.name}
              </Text>
              <Text component="h3" c={theme.colors.gray[6]} mb={isWaitlist ? '4px' : 'xs'}>
                {activity?.supplier.name}
              </Text>
              {isWaitlist && <Badge className={classes.waitlistBadge}>Waitlist</Badge>}
            </Box>
          </Group>
        </section>

        <Box>
          <SessionDateList
            dates={dates || []}
            subscriptionInfo={subscriptionInfo}
            isWaitlist={isWaitlist}
            hideEndTimes={activity?.hideEndTimes}
          />
        </Box>
      </div>
    </>
  );
};

export default ActivityPreviewCard;
