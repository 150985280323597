import { Affix, Badge, Button, Card, Collapse, Stack, useMantineTheme } from '@mantine/core';
import { useBasket } from 'context/BasketContext';
import BasketSessionsList from './BasketSessionsList';
import GetTicketsButton from 'components/GetTicketsButton/GetTicketsButton';
import { SocialProofLabelEnum } from 'enums';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './BasketPopup.module.scss';
import classNames from 'classnames';
import { CaretUp } from '@phosphor-icons/react';
import { CaretDown } from '@phosphor-icons/react';

interface IBasketPopupProps {
  socialProofBadge: SocialProofLabelEnum | null;
  socialProofBannerTitleEventProperty?: () => string | null;
}

const BasketPopup: React.FC<IBasketPopupProps> = ({
  socialProofBadge,
  socialProofBannerTitleEventProperty,
}) => {
  const theme = useMantineTheme();

  const {
    basketItems,
    basketPopupOpened,
    toggleBasketPopup,
    activityDetails,
    setShowTicketTypesModal,
  } = useBasket();

  return (
    <>
      <Affix mih={70} mah="100vh" w="100%" data-testid="basket-popup">
        <Button
          fullWidth
          h={70}
          onClick={toggleBasketPopup}
          classNames={{
            root: classes.button,
            inner: classes.buttonInner,
          }}
          className={classNames({ [classes.opened]: basketPopupOpened })}
          rightSection={
            basketPopupOpened ? (
              <CaretDown size={20} weight="bold" />
            ) : (
              <CaretUp size={20} weight="bold" />
            )
          }
        >
          Sessions{' '}
          <Badge color={theme.colors.blue[6]} radius="xl" ml={theme.spacing.xs} variant="filled">
            {basketItems?.length}
          </Badge>
        </Button>
        <Collapse in={basketPopupOpened}>
          <Stack className={classes.basketWrapper} mt={-1} style={{ width: '100%' }}>
            <Card
              radius={10}
              style={{
                boxShadow: '0px 4px 24px 0px #0B174E1A',
                maxHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
            >
              <BasketSessionsList
                basketItems={basketItems || []}
                hideEndTimes={activityDetails?.activity.hideEndTimes}
              />
            </Card>

            <GetTicketsButton
              mt="md"
              fullWidth
              radius="xl"
              size="lg"
              onClick={() => {
                trackAction(Actions.GET_TICKETS, {
                  activityLocation: activityDetails?.activity.isOnline ? 'online' : 'in-person',
                  activityType: activityDetails?.activity.activityType,
                  ...(socialProofBadge && {
                    socialProofBadge,
                  }),
                  ...(socialProofBannerTitleEventProperty && {
                    socialProofBanner: socialProofBannerTitleEventProperty(),
                  }),
                });
                setShowTicketTypesModal(true);
              }}
            >
              Book now
            </GetTicketsButton>
          </Stack>
        </Collapse>
      </Affix>
    </>
  );
};

export default BasketPopup;
