import { Flex, Group, Text, useMantineTheme } from '@mantine/core';
import { Product } from 'types';
import React from 'react';
import dayjs from 'dayjs';
import { useBasket } from 'context/BasketContext';
import classes from './BasketDrawer.module.scss';
import { X } from '@phosphor-icons/react';

interface BasketDrawerItemProps {
  product: Product;
  maxItems: number;
  hideEndTimes?: boolean;
}

const BasketDrawerItem: React.FC<BasketDrawerItemProps> = ({
  product,
  maxItems,
  hideEndTimes = false,
}) => {
  const theme = useMantineTheme();

  const formattedDate = (singleSessionDate: string | null | undefined) => {
    return dayjs(singleSessionDate).format('ddd Do MMM');
  };

  const { removeFromBasket } = useBasket();

  return (
    <Flex
      justify="space-between"
      align="center"
      style={{ width: '100%' }}
      className={classes.productItem}
      maw={`calc((100% - ${maxItems} * 8px) / ${maxItems})`}
    >
      <Group>
        <Text c={theme.colors.blue[8]}>{formattedDate(product.singleSessionDate)}</Text>
        <Text fw="bold">
          {hideEndTimes ? product.startTime : `${product.startTime} - ${product.endTime}`}
        </Text>
      </Group>
      <Flex
        onClick={() => removeFromBasket(product.checkoutValue)}
        style={{ cursor: 'pointer' }}
        direction={'column'}
      >
        <X size={15} weight="bold" />
      </Flex>
    </Flex>
  );
};

export default BasketDrawerItem;
