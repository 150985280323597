import React, { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import {
  Box,
  Text,
  Badge,
  Notification,
  Flex,
  useMantineTheme,
  Combobox,
  InputBase,
  Input,
  useCombobox,
  Button,
  Stack,
  Group,
  Modal,
  // SimpleGrid,
} from '@mantine/core';
import DaysOfWeekDisplayLine from 'components/ui/DaysOfWeekDisplayLine/DaysOfWeekDisplayLine';
import TimesAndAvailability from 'components/ActivityDetails/TimesAndAvailability/InstantBookTimesAndAvailability';
import classes from './SessionSelect.module.scss';
import {
  filterMultiSessionTickets,
  handleCreateAccountModalClick,
  toTicketInputType,
} from 'components/ActivityDetails/ActivityDetails.utils';
import {
  ActivityDetails as ActivityDetailsType,
  Product,
  CreateBasketInput,
  ProductsByDate,
} from 'types';
import { ActivityTicket } from 'interfaces';
import { SocialProofLabelEnum, ActivityTypeEnum, ActivityBlockSubtypeEnum } from 'enums';
import BasketPopup from 'components/BasketPopup/BasketPopup';
import { useBasket } from 'context/BasketContext';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import BasketDrawer from 'components/BasketDrawer/BasketDrawer';
import { UtmParamsData } from 'components/ActivityDetails/ActivityDetails';
import { useMutation } from '@apollo/client';
import { CreateBasketMutation } from 'graphql/mutations';
import { useRouter } from 'next/router';
import { Actions, trackAction } from 'utils/amplitude';
import TicketTypesSelectionModal from 'components/TicketTypesSelectionModal/TicketTypesSelectionModal';
import CreateAccountModal from 'components/CreateAccountModal/CreateAccountModal';
import { CalendarBlank, CaretDown, CaretUp, Clock, Info, Ticket } from '@phosphor-icons/react';
import SubsTimesAndAvailability from 'components/ActivityDetails/TimesAndAvailability/SubsTimesAndAvailability';
import classNames from 'classnames';

interface ISessionSelectProps {
  activityDetails: ActivityDetailsType;
  handleGetTickets: (session: Product) => void;
  socialProofBadge: SocialProofLabelEnum | null;
  selectedSession: Product | null;
  setSelectedSession(val: Product | null): void;
  setUserSelectedRadio?(val: boolean): void;
  isStandalone?: boolean;
  blockBookingTabActive: boolean;
  setBlockBookingTabActive: React.Dispatch<React.SetStateAction<boolean>>;
  socialProofBannerTitleEventProperty?: () => string | null;
  utmParams?: UtmParamsData;
}

interface IClassesOptionsProps {
  name: string;
  dateRange: string;
  timeRanges: string[];
}

const ClassesOptions: React.FC<IClassesOptionsProps> = ({ name, dateRange, timeRanges }) => {
  const theme = useMantineTheme();
  const moreTimesCount = timeRanges.length - 4;
  return (
    <Stack gap={12} className={classes.classesOptions}>
      <Text c={theme.colors.blue[8]} size="md" fw={600}>
        {name}
      </Text>
      <Flex align={'center'} gap={8}>
        <CalendarBlank size={20} color={theme.colors.blue[8]} />
        <Text c={theme.colors.blue[8]} size="md" fw={600}>
          {dateRange}
        </Text>
      </Flex>
      <Group gap={12}>
        {timeRanges.map((time, index) => {
          if (index < 3 || (index === 3 && timeRanges.length === 4)) {
            return (
              <Flex gap={8} key={index}>
                <Clock size={20} color={theme.colors.gray[6]} />
                <Text c={theme.colors.gray[6]} fw={600} size={'sm'}>
                  {time}
                </Text>
              </Flex>
            );
          }
        })}
        {timeRanges.length > 4 && (
          <Text c={theme.colors.gray[6]} fw={600} size={'sm'}>
            + {moreTimesCount} more time{`${moreTimesCount === 1 ? '' : 's'}`}{' '}
          </Text>
        )}
      </Group>
    </Stack>
  );
};

const SessionSelect: React.FC<ISessionSelectProps> = ({
  activityDetails,
  handleGetTickets,
  socialProofBadge,
  selectedSession,
  setSelectedSession,
  setUserSelectedRadio,
  isStandalone = false,
  blockBookingTabActive,
  setBlockBookingTabActive,
  socialProofBannerTitleEventProperty,
  utmParams = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
  },
}) => {
  const theme = useMantineTheme();
  const { activity, classes: classesObj } = activityDetails;

  const Router = useRouter();
  const { push: routerPush } = Router;

  const {
    basketItems,
    setBasketItems,
    selectedTickets,
    setSelectedTickets,
    showTicketTypesModal,
    setShowTicketTypesModal,
    basketActivityTickets,
    userToken,
    showCreateAccountModal,
    setShowCreateAccountModal,
    selectedClass,
    setSelectedClass,
  } = useBasket();

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const isSubscription = activity.activityType === ActivityTypeEnum.SUBSCRIPTION;

  const isSingleClass = classesObj.length === 1;

  const [showSessionSelect, setShowSessionSelect] = useState<boolean>(
    isSubscription || isSingleClass,
  );
  const [showSelectionError, setShowSelectionError] = useState<boolean>(false);

  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    setSelectedTickets([]);
  }, [basketActivityTickets, setSelectedTickets]);

  useEffect(() => {
    if (classesObj.length === 1) {
      setSelectedClass(classesObj[0]);
    }
  }, [classesObj, setSelectedClass]);

  const isMultiSingleSession = useMemo(() => {
    if (selectedSession?.checkoutKey === 'block') {
      return false;
    }
    if (basketItems && (basketItems.length > 0 || basketItems.length === 1)) {
      return true;
    }
    return false;
  }, [basketItems, selectedSession]);

  const someDatesSoldOut = useMemo(() => {
    if (isSubscription) {
      return false;
    }
    const { blocks, sessions } = activityDetails.classes[0];

    const soldOutBlock = activity.allowBlockBookings
      ? blocks.some((booking) =>
          booking.allBlockDates?.some((date) => date.spotsLeft === 0 && !date.isInPast),
        )
      : false;

    const soldOutSingle = activity.allowIndividualBookings
      ? sessions.some((session) => session.products?.some((product) => product.spotsLeft === 0))
      : false;

    return soldOutBlock || soldOutSingle;
  }, [
    activityDetails,
    activity.allowBlockBookings,
    activity.allowIndividualBookings,
    isSubscription,
  ]);

  const showBasket = useMemo(() => {
    return basketItems && basketItems.length > 0 && !blockBookingTabActive;
  }, [basketItems, blockBookingTabActive]);

  const [basketCreate, { loading }] = useMutation(CreateBasketMutation, {
    onError: (error) => {
      console.log(error);
    },
  });

  const createBasketMutation = async (input: CreateBasketInput): Promise<string> => {
    const { data } = await basketCreate({
      variables: {
        input,
      },
      ...(userToken && {
        context: {
          headers: {
            Authorization: `${userToken}`,
          },
        },
      }),
    });

    return data.createBasket.id;
  };

  const getBasketInput = (ticketId?: string) => {
    const createBasketInput: CreateBasketInput = {
      activity: activityDetails.activity.id,
      tickets: isMultiSingleSession
        ? filterMultiSessionTickets(selectedTickets || [], basketItems || [])
        : toTicketInputType(selectedTickets || [], selectedSession, ticketId),
      utmMeta:
        Object.keys(utmParams).length === 0
          ? {}
          : {
              utm_source: utmParams?.utm_source ?? null,
              utm_medium: utmParams?.utm_medium ?? null,
              utm_campaign: utmParams?.utm_campaign ?? null,
            },
    };

    return createBasketInput;
  };

  const redirectToCheckout = (basketId: string) => {
    const params = {
      basketId,
    };
    Router.push(`/checkout/details?${qs.stringify(params)}`);
  };

  const handleBookNow = async (ticket?: ActivityTicket) => {
    if (ticket) {
      setSelectedTickets([
        {
          ticketId: ticket.id,
          ticketPrice: ticket.price,
          capacity: ticket.capacity,
          pricingPolicy: ticket.pricingPolicy,
        },
      ]);
    } else if (
      (selectedTickets && selectedTickets.length === 0) ||
      !basketItems ||
      !selectedSession
    ) {
      return;
    }

    if (!userToken) {
      setShowCreateAccountModal(true);
    } else {
      const basketInput = getBasketInput(ticket?.id);

      const basketId = await createBasketMutation(basketInput);

      setShowTicketTypesModal(false);

      setShowCreateAccountModal(false);

      redirectToCheckout(basketId);
    }
    trackAction(Actions.BOOK_BASKET, {
      activityLocation: activityDetails?.activity.isOnline ? 'online' : 'in-person',
      activityType: activityDetails?.activity.activityType,
      ...(socialProofBadge && {
        socialProofBadge,
      }),
      ...(socialProofBannerTitleEventProperty && {
        socialProofBanner: socialProofBannerTitleEventProperty(),
      }),
    });
  };

  const handleCreateAccountModalClickWrapper = (routeName: string) => {
    return handleCreateAccountModalClick(
      { getBasketInput, createBasketMutation, routerPush },
      routeName,
    );
  };

  const showTicketModal = useMemo<boolean>(() => {
    const hasSelection = Boolean(selectedSession) || Boolean(basketItems[0]);
    return Boolean(hasSelection && showTicketTypesModal);
  }, [selectedSession, basketItems, showTicketTypesModal]);

  const getTimeRanges = (blocks: Product[], sessions: ProductsByDate[], hideEndTimes: boolean) => {
    const timeRanges = new Set();

    blocks.forEach((block) => {
      const timeRange = hideEndTimes ? block.startTime : `${block.startTime} - ${block.endTime}`;
      timeRanges.add(timeRange);
    });

    sessions.forEach((session) => {
      session.products.forEach((product) => {
        const timeRange = hideEndTimes
          ? product.startTime
          : `${product.startTime} - ${product.endTime}`;
        timeRanges.add(timeRange);
      });
    });

    return Array.from(timeRanges);
  };

  const options = classesObj.map(({ name, id, blocks, sessions, dateRange }) => {
    const timeRanges = getTimeRanges(blocks, sessions, activity.hideEndTimes) as string[];

    if (isMobile) {
      return (
        <Box
          key={id}
          mb={12}
          onClick={() => {
            setSelectedClass(classesObj.filter((singleClass) => singleClass.id === id)[0]);
            setShowSessionSelect(true);
            setBasketItems([]);
            close();
          }}
        >
          <ClassesOptions name={name} dateRange={dateRange} timeRanges={timeRanges} />
        </Box>
      );
    }

    return (
      <React.Fragment key={id}>
        <Combobox.Option value={id} key={id} className={classes.classSelect}>
          <ClassesOptions name={name} dateRange={dateRange} timeRanges={timeRanges} />
        </Combobox.Option>
        <div className={classes.dotted}></div>
      </React.Fragment>
    );
  });

  return (
    <>
      <Flex direction={'column'} mx={0}>
        {!isMobile && activity.activityType !== ActivityTypeEnum.SUBSCRIPTION && !isSingleClass && (
          <>
            <Combobox
              store={combobox}
              onOptionSubmit={(val) => {
                setSelectedClass(classesObj.filter((singleClass) => singleClass.id === val)[0]);
                setShowSelectionError(false);
                setShowSessionSelect(true);
                combobox.closeDropdown();

                //   Reset basket context state on unmount
                setBasketItems([]);
              }}
            >
              <Combobox.Target>
                <InputBase
                  label={
                    <Flex align={'center'} gap={8} mb={12} fs="lg">
                      <Text fw={700} c={theme.colors.blue[8]}>
                        Make a booking
                      </Text>
                      <Info size={16} fill={theme.colors.blue[8]} />
                    </Flex>
                  }
                  component="button"
                  type="button"
                  pointer
                  rightSection={
                    combobox.dropdownOpened ? (
                      <CaretUp size={16} color={theme.colors.blue[8]} />
                    ) : (
                      <CaretDown size={16} color={theme.colors.blue[8]} />
                    )
                  }
                  rightSectionPointerEvents="none"
                  onClick={() => combobox.toggleDropdown()}
                  classNames={{
                    input: classNames(classes.bookingSelectInput, {
                      [classes.bookingSelectInputError]: showSelectionError,
                    }),
                    section: classes.bookingSelectSection,
                  }}
                >
                  {selectedClass ? (
                    <Text c={theme.colors.blue[8]} fw={600} size="md">
                      {selectedClass.name}
                    </Text>
                  ) : (
                    <Input.Placeholder className={classes.classSelectPlaceholder}>
                      Please select a class
                    </Input.Placeholder>
                  )}
                </InputBase>
              </Combobox.Target>

              <Combobox.Dropdown className={classes.dropdown}>
                <Combobox.Options>{options}</Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
            {showSelectionError && (
              <Text c={theme.colors.red[6]} size="sm">
                Please select a class
              </Text>
            )}
          </>
        )}
        {isMobile && activity.activityType !== ActivityTypeEnum.SUBSCRIPTION && !isSingleClass && (
          <>
            <InputBase
              label={
                <Flex align={'center'} gap={8} mb={12} fs="lg">
                  <Text fw={700} c={theme.colors.blue[8]}>
                    Make a booking
                  </Text>
                  <Info size={16} fill={theme.colors.blue[8]} />
                </Flex>
              }
              component="button"
              type="button"
              pointer
              rightSection={
                combobox.dropdownOpened ? (
                  <CaretUp size={16} color={theme.colors.blue[8]} />
                ) : (
                  <CaretDown size={16} color={theme.colors.blue[8]} />
                )
              }
              rightSectionPointerEvents="none"
              onClick={open}
              classNames={{
                input: classes.bookingSelectInput,
                section: classes.bookingSelectSection,
              }}
            >
              {selectedClass ? (
                <Text c={theme.colors.blue[8]} fw={600} size="md">
                  {selectedClass.name}
                </Text>
              ) : (
                <Input.Placeholder className={classes.classSelectPlaceholder}>
                  Please select a class
                </Input.Placeholder>
              )}
            </InputBase>
          </>
        )}
        {showSessionSelect && (selectedClass || isSubscription) && (
          <>
            <Box>
              {(selectedClass?.dateRange ||
                activity.activityType === ActivityTypeEnum.SUBSCRIPTION) && (
                <Box className={classes.detailsItemDateRange}>
                  <CalendarBlank size={20} color={theme.colors.blue[8]} />
                  <Text component="p" c={theme.colors.blue[8]} fw={600} size="md">
                    {activity.activityType === ActivityTypeEnum.SUBSCRIPTION
                      ? 'Weekly sessions'
                      : selectedClass?.dateRange}
                  </Text>
                </Box>
              )}
              {activity.activityType !== ActivityTypeEnum.ONE_OFF && (
                <Box className={classes.daysOfWeekDisplayLine}>
                  <DaysOfWeekDisplayLine
                    weekdays={selectedClass?.weekdays || activityDetails.weekdays || []}
                    selectedLimeBg
                    fullWidth
                    enlarged
                  />
                </Box>
              )}
              {someDatesSoldOut && (
                <Badge className={classes.someDatesSoldOut}>
                  {!activityDetails.anySpotsLeft ? 'All dates sold out' : 'Some dates sold out'}
                </Badge>
              )}
              {activity?.blockSubtype === ActivityBlockSubtypeEnum.ALL_DAYS && (
                <Notification
                  withCloseButton={false}
                  radius={0}
                  classNames={{
                    icon: classes.notificationIcon,
                    root: classes.notificationRoot,
                    title: classes.notificationTitle,
                  }}
                  icon={<Info size={26} color={theme.colors.blue[8]} />}
                  title={
                    selectedClass?.sessions.length === 0
                      ? 'Tickets will be booked for all dates listed.'
                      : 'Tickets will be booked for all dates listed \n or you can book a single session in the Single tab.'
                          .split('\n')
                          .map((paragraph, key) => <div key={key}>{paragraph}</div>)
                  }
                />
              )}
            </Box>
            <Flex direction={'column'} align={'center'} mb={12}>
              <Box
                component="section"
                aria-label="times and availability"
                id="times-and-availability"
                className={(classes.anchorTag, classes.card)}
              >
                {selectedClass && !isSubscription ? (
                  <TimesAndAvailability
                    activityDetails={activityDetails}
                    selectedClass={selectedClass}
                    handleGetTickets={handleGetTickets}
                    blockBookingTabActive={blockBookingTabActive}
                    setBlockBookingTabActive={setBlockBookingTabActive}
                    socialProofBadge={socialProofBadge}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                    setUserSelectedRadio={setUserSelectedRadio}
                    socialProofBannerTitleEventProperty={socialProofBannerTitleEventProperty}
                  />
                ) : (
                  <SubsTimesAndAvailability
                    activityDetails={activityDetails}
                    handleGetTickets={handleGetTickets}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                    setUserSelectedRadio={setUserSelectedRadio}
                  />
                )}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
      {!showSessionSelect && (
        <Button
          mt={12}
          mb={18}
          className={classes.chooseTicketsBtn}
          leftSection={<Ticket size={16} />}
          onClick={() => {
            if (!selectedClass) return setShowSelectionError(true);
            setShowSessionSelect(true);
          }}
          fullWidth={true}
        >
          Choose tickets
        </Button>
      )}
      {(isMobile || isStandalone) && showBasket && (
        <BasketPopup
          socialProofBadge={socialProofBadge}
          socialProofBannerTitleEventProperty={socialProofBannerTitleEventProperty}
        />
      )}
      {showBasket && !isMobile && !isStandalone && (
        <BasketDrawer
          socialProofBadge={socialProofBadge}
          socialProofBannerTitleEventProperty={socialProofBannerTitleEventProperty}
        />
      )}
      <Modal
        opened={opened}
        onClose={close}
        title={activity.name}
        fullScreen={true}
        bg={'#F7F7F7'}
        classNames={{
          content: classes.modalContent,
          header: classes.modalHeader,
          title: classes.modalTitle,
          body: classes.modalBody,
        }}
      >
        <Text size="lg" fw={600} c={theme.colors.gray[6]}>
          Select a class
        </Text>
        {options}
        <Text c={theme.colors.gray[5]} size="sm" fw={600} mt={12} ta="center">
          You have reached the end of the list
        </Text>
      </Modal>
      {showTicketModal && basketActivityTickets && (
        <TicketTypesSelectionModal
          supplierId={activity.supplier.id}
          data-cy="modal"
          opened={showTicketTypesModal || false}
          onClose={() => {
            setShowTicketTypesModal(false);
            setSelectedTickets([]);
          }}
          withCloseButton={false}
          name={activityDetails?.activity.name}
          selectedSession={selectedSession}
          selectedTickets={selectedTickets || []}
          setSelectedTickets={setSelectedTickets}
          handleBookNow={handleBookNow}
          loading={loading}
          activityType={activityDetails?.activity.activityType}
          tickets={basketActivityTickets || []}
          fromBasket={selectedSession?.checkoutKey !== 'block' && Boolean(basketItems[0])}
          socialProofBadge={socialProofBadge}
          activityId={activity.id}
          supplierName={activity.supplier.name}
        />
      )}
      <CreateAccountModal
        data-cy="modal"
        opened={showCreateAccountModal || false}
        onClose={() => setShowCreateAccountModal(false)}
        withCloseButton={false}
        handleCreateAccountModalClick={handleCreateAccountModalClickWrapper}
      />
    </>
  );
};

export default SessionSelect;
