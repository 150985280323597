import { ActionIcon, Box, Button, Modal, Stack, Text } from '@mantine/core';
import { SocialProofLabelEnum } from 'enums';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './LeavingModal.module.scss';
import { X } from '@phosphor-icons/react';
interface ILeavingModalProps {
  opened: boolean;
  onClose(): void;
  goBack: () => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  socialProofBadge: SocialProofLabelEnum | null;
  hasOneSpotLeft: boolean;
  preCheckoutPage?: boolean;
}

const LeavingModal: React.FC<ILeavingModalProps> = ({
  opened,
  onClose,
  goBack,
  onClick,
  socialProofBadge,
  hasOneSpotLeft,
  preCheckoutPage,
}: ILeavingModalProps) => {
  const getModalMessage = () => {
    if (hasOneSpotLeft) {
      return 'One or more of your selected sessions has just 1 spot left. Book now to secure your place!';
    } else if (socialProofBadge === SocialProofLabelEnum.limitedSpots) {
      return "This activity has limited spots left. Book now and secure yours before it's too late!";
    } else if (socialProofBadge === SocialProofLabelEnum.popular) {
      return 'This activity is popular with others! Hurry and secure your place now!';
    } else {
      return '';
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        withCloseButton={false}
        centered
        size={500}
        padding={0}
        classNames={{
          content: classes.modalContent,
          body: classes.modalBody,
        }}
      >
        <ActionIcon
          title="Close"
          onClick={onClose}
          className={classes.closeButton}
          variant="filled"
        >
          <X size={18} weight="bold" />
        </ActionIcon>
        <Stack py="xl" px="lg" className={classes.contentWrapper}>
          <Text className={classes.modalTitle}>Are you sure you want to leave?</Text>
          <Box>
            <Text pb={0}>{`Your ${preCheckoutPage ? 'ticket' : ''} selection will be lost!`}</Text>
            <Text pt={0}>{getModalMessage()}</Text>
          </Box>
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              onClose();
              onClick?.(e);
              const trackingAction = preCheckoutPage
                ? Actions.LEAVING_MODAL_CONTINUE_PRE_CHECKOUT
                : Actions.LEAVING_MODAL_CONTINUE_ADP;
              trackAction(trackingAction);
            }}
            className={classes.pinkButton}
          >
            {preCheckoutPage ? 'Checkout Now' : 'Continue Booking'}
          </Button>
          <Button
            onClick={() => {
              goBack();
              const trackingAction = preCheckoutPage
                ? Actions.LEAVING_MODAL_BACK_PRE_CHECKOUT
                : Actions.LEAVING_MODAL_BACK_ADP;
              trackAction(trackingAction);
            }}
            className={classes.outlinePinkButton}
            variant="outline"
          >
            {preCheckoutPage ? 'Back to Activity details' : 'Back to Browsing'}
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default LeavingModal;
