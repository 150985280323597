import dayjs from 'dayjs';
import { Activity, Child, Product } from 'types';
import {
  PreCheckoutBasket,
  PreCheckoutBasketAttendee,
  PreCheckoutBasketTicket,
  Addons,
  CustomQuestionItem,
} from 'interfaces';
import { ActivityTypeEnum, StepperEnum } from 'enums';
import ageInRange from 'utils/ageInRange';
import { MutableRefObject } from 'react';

type Labels = {
  [key in StepperEnum]?: string;
};

export const getPreviousLabel = (
  activeStep: StepperEnum,
  hasAddOns: boolean,
  preCheckoutFormRequired: boolean,
): string => {
  const labels: Labels = {
    [StepperEnum.CONFIRM_ATTENDEES]: 'Back to activity details',
    [StepperEnum.ANSWER_QUESTIONS]: 'Back to Attendees',
    [StepperEnum.SELECT_ADDONS]: preCheckoutFormRequired
      ? 'Back to Questions'
      : 'Back to Attendees',
  };

  if (labels[activeStep]) {
    return labels[activeStep] as string;
  }

  if (hasAddOns) {
    return 'Back to Add-Ons';
  }

  return preCheckoutFormRequired ? 'Back to Questions' : 'Back to Attendees';
};

export const validateTelephone = (value: string) => {
  const regex = new RegExp(/(^(?=[0-9]{11}$)(0)\d+)|(^(?=[0-9]{10}$)(7)\d+)/);
  return regex.test(value);
};

export const getExistingTelephone = (telephone?: string): string => {
  if (!telephone) {
    return '';
  }
  if (telephone.startsWith('+44')) {
    return telephone.slice(3);
  }
  return telephone;
};

export const isEligibleChild = (childId?: string | null, registeredAttendees?: Child[]) => {
  if (registeredAttendees && childId) {
    return registeredAttendees.some((child) => child.id === childId);
  }
  return false;
};

export const getTicketsWithAttendees = (
  basket: PreCheckoutBasket,
  isSubscription: boolean,
  registeredAttendees?: Child[],
) => {
  const ticketQuantity = basket.tickets.length;
  return basket.tickets.map((ticket) => {
    const subscriptionTrialSelected = basket?.tickets[0].subscriptionTrialSelected || false;

    const existingAttendees: PreCheckoutBasketAttendee[] = ticket.attendees.map((attendee) => {
      if (isEligibleChild(attendee.id, registeredAttendees)) {
        return {
          id: attendee.id,
        };
      }

      return {
        id: attendee.id,
      };
    });

    if (ticketQuantity === existingAttendees.length) {
      return {
        ...ticket,
        attendees: existingAttendees,
        ...(isSubscription && {
          subscriptionTrialSelected,
        }),
      };
    } else {
      const attendeesArray = new Array(ticket.ticketCapacity - existingAttendees.length).fill({
        id: null,
      });

      return {
        ...ticket,
        attendees: existingAttendees.concat(attendeesArray),
        ...(isSubscription && {
          subscriptionTrialSelected,
        }),
      };
    }
  });
};

export const getBasketTicketsCount = (tickets: PreCheckoutBasketTicket[]) => {
  if (!tickets) {
    return 0;
  }
  return tickets.length;
};

export const getFormattedActivityDate = (product: Product, activity: Activity) => {
  if (!product) {
    return '';
  }
  if (product.singleSessionDate) {
    return dayjs(product.singleSessionDate).format('ddd Do MMMM');
  }
  if (product.dateRange) {
    return product.dateRange;
  }
  if (activity.activityType === ActivityTypeEnum.SUBSCRIPTION) {
    return product?.weekdays?.[0] || '';
  }
  return '';
};

export const getAddOnCountString = (addOns?: Addons[]): string => {
  if (!addOns) {
    return '';
  }
  const allAddOns = addOns.flatMap((addOn) => [
    ...(addOn.perSession ?? []),
    ...(addOn.perBooking ?? []),
  ]);
  const addOnInfo = allAddOns.reduce((acc, curr) => {
    const quantity =
      'quantity' in curr
        ? curr.quantity
        : 'selectedSessions' in curr
        ? curr.selectedSessions.length
        : 0;
    if (quantity === 0) {
      return acc;
    }
    const key = curr.addonOption.id;
    const existing = acc[key];
    return {
      ...acc,
      [key]: {
        ...(existing
          ? {
              ...existing,
              totalAmount: existing.totalAmount + quantity,
            }
          : {
              name: curr.addonOption.name,
              totalAmount: quantity,
            }),
      },
    };
  }, {} as Record<string, { name: string; totalAmount: number }>);
  return Object.values(addOnInfo)
    .map((info) => `${info.totalAmount} x ${info.name}`)
    .join(', ');
};

export const findAttendeesOutOfAgeRange = (
  tickets: PreCheckoutBasketTicket[],
  ageMonthsStart: number,
  ageMonthsEnd: number,
  registeredAttendees: Child[] = [],
) => {
  const allAttendee = tickets.flatMap((ticket) => {
    return ticket.attendees.map((attendee) => {
      const matchingAttendee = registeredAttendees.find((_a) => _a.id === attendee.id);
      if (matchingAttendee && !matchingAttendee.isAdult) {
        return matchingAttendee;
      }

      if (!attendee.isAdult) {
        return attendee;
      }

      return undefined;
    });
  });

  if (!allAttendee) {
    return [];
  }

  return allAttendee.filter(
    (attendee) =>
      attendee?.birthDate && !ageInRange(attendee.birthDate, ageMonthsStart, ageMonthsEnd),
  );
};

export const calculateAgeInYearsAndMonths = (birthDate?: string) => {
  if (!birthDate) return;
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  const yearsDiff = today.getFullYear() - birthDateObj.getFullYear();
  const monthsDiff = today.getMonth() - birthDateObj.getMonth();
  const daysDiff = today.getDate() - birthDateObj.getDate();
  // Subtract one month if not reached the birth day yet this month
  const monthsDiffToDate = daysDiff < 0 ? monthsDiff - 1 : monthsDiff;

  if (monthsDiffToDate < 0) {
    return `${yearsDiff - 1}yrs ${monthsDiffToDate + 12}mths`;
  }

  return `${yearsDiff}yrs ${monthsDiffToDate}mths`;
};

export const formatTelephoneValue = (telephoneNumber: string) => {
  const regex = new RegExp(/^(?=[0-9]{10}$)(7)\d+/);
  const telephonePrefix = '+44';

  if (regex.test(telephoneNumber)) {
    return telephonePrefix + telephoneNumber;
  } else {
    const telephoneNumberWithoutZero = telephoneNumber.substring(1);
    return telephonePrefix + telephoneNumberWithoutZero;
  }
};

export const getSteps = (hasAddOns: boolean, preCheckoutFormRequired: boolean) => {
  const steps = [StepperEnum.CONFIRM_ATTENDEES];
  if (preCheckoutFormRequired) {
    steps.push(StepperEnum.ANSWER_QUESTIONS);
  }
  if (hasAddOns) {
    steps.push(StepperEnum.SELECT_ADDONS);
  }
  steps.push(StepperEnum.REVIEW);
  return steps;
};

export const addAttendeesToTheForm = (basket: PreCheckoutBasket) => {
  const getAddedAttendee = (question: CustomQuestionItem) => {
    return basket.tickets.flatMap((ticket: PreCheckoutBasketTicket) => {
      return ticket.attendees.map((attendee) => {
        const previousAttendee = question.attendeeAnswers?.find(
          (attendeeAnswer) => attendeeAnswer.attendeeId === attendee?.id,
        );
        return {
          attendeeId: attendee?.id || '',
          fullName: attendee.fullName || '',
          answer: previousAttendee?.answer || previousAttendee?.previousAnswer || '',
        };
      });
    });
  };

  return basket.questionAnswers.map((questionItem: CustomQuestionItem) => {
    return {
      ...questionItem,
      bookerAnswer: questionItem.question.isPerAttendee
        ? null
        : questionItem.bookerAnswer || questionItem.previousBookerAnswer || '',
      attendeeAnswers: !questionItem.question.isPerAttendee ? null : getAddedAttendee(questionItem),
    };
  });
};

export const executeScroll = (stepperRef?: MutableRefObject<HTMLDivElement | null>) =>
  stepperRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

export const getBookedDatesList = (
  product?: Product,
  basketTickets?: PreCheckoutBasketTicket[],
) => {
  if (!product) {
    return null;
  }
  const isBlockBooking = product.allBlockDates && product.allBlockDates.length > 0;
  const getSelectedSingleSessionDate = () => {
    return [
      {
        dayOfTheWeek: product.weekdays,
        startTime: product.startTime,
        endTime: product.endTime,
        spotsLeft: product.spotsLeft,
        date: product.singleSessionDate || '',
        isInPast: false,
      },
    ];
  };

  if (isBlockBooking) {
    return product.allBlockDates?.filter((date) => !date.isInPast);
  }
  return basketTickets ? basketTickets[0].sessions : getSelectedSingleSessionDate();
};

export const getBookedSubscriptionInfo = (product?: Product) => {
  if (!product || !product.subscriptionNextSessionDate) {
    return null;
  }
  return {
    date: product.subscriptionNextSessionDate,
    startTime: product.startTime,
    endTime: product.endTime,
  };
};
