import dayjs from 'dayjs';
import { CreateBasketInput, Product, SelectedTickets, TicketInput } from 'types';
import { ActivityTicket, Supplier } from 'interfaces';
import qs from 'query-string';
import { formatPenceToPounds } from 'utils/formatPrice';

export const toTicketInputType = (
  tickets: Partial<SelectedTickets>[] | null,
  session: Product | null | undefined,
  ticketId?: string,
): TicketInput[] => {
  if (!session) return [];

  if (ticketId) {
    return [
      {
        ticket: ticketId,
        [session?.checkoutKey]: session?.checkoutValue,
        checksum: session.checksum,
      },
    ];
  }

  const selectedTicketsExcludingPrice = tickets?.map((ticket) => {
    return {
      ticket: ticket.ticketId,
      [session?.checkoutKey]: session?.checkoutValue,
      checksum: session.checksum,
    };
  });

  return selectedTicketsExcludingPrice || [];
};

export const filterMultiSessionTickets = (
  tickets: Partial<SelectedTickets>[],
  sessions: Product[],
) => {
  if (!tickets || !sessions) return;

  const ticketsWithSessions = tickets.map((ticket) => {
    return {
      ticket: ticket.ticketId,
      sessions: sessions.map((session) => session.checkoutValue),
    };
  });

  return ticketsWithSessions;
};

export const filterMultiSessionTicketsFromSessionIds = (
  tickets: Partial<SelectedTickets>[],
  sessions: string[],
) => {
  if (!tickets || !sessions) return;

  const ticketsWithSessions = tickets.map((ticket) => {
    return {
      ticket: ticket.ticketId,
      sessions: sessions,
    };
  });

  return ticketsWithSessions;
};

export const priceText = (opts: { bookingPrice?: number; subscription?: boolean }) => {
  if (opts.bookingPrice) {
    const bookingPriceInPounds = opts.bookingPrice / 100;

    return `£${bookingPriceInPounds.toFixed(2)} / ${opts.subscription ? 'month' : 'session'}`;
  }
};

export const supplierDetailsExist = (supplier: Supplier): boolean => {
  if (!supplier) {
    return false;
  }

  return Boolean(
    supplier?.aboutUs &&
      supplier?.ageRange &&
      supplier?.cloudinaryImageId &&
      supplier?.activitiesLocation,
  );
};

type THandleCreateAccountModalClickDependencies = {
  getBasketInput(): CreateBasketInput;
  createBasketMutation(input: CreateBasketInput): Promise<string>;
  routerPush(link: string): void;
};

export const handleCreateAccountModalClick = async (
  dependencies: THandleCreateAccountModalClickDependencies,
  routeName: string,
) => {
  const basketInput = dependencies.getBasketInput();
  const basketId = await dependencies.createBasketMutation(basketInput);

  if (routeName === 'guest') {
    dependencies.routerPush(`/checkout/details?basketId=${basketId}`);
  } else {
    const params = {
      redirect: `${window?.location.origin}/checkout/details`,
      basketId: basketId,
    };

    const link = `${process.env.NEXT_PUBLIC_PEBBLE_APP_ENDPOINT}${routeName}?${qs.stringify(
      params,
    )}`;
    dependencies.routerPush(link);
  }
};

export const roundUpToClosestIncrementOf5 = (totalActivityBookings: number) => {
  // Calculate the remainder when dividing the number by 5
  const remainder = totalActivityBookings % 5;
  // If remainder is greater than 0, round up to the next minimal increment of 5
  if (remainder > 0) {
    return totalActivityBookings - remainder;
  }
  return totalActivityBookings;
};

export const getHoursSinceLastBooking = (lastBookingTime: string) => {
  const currentTime = dayjs();
  const lastBookingTimestamp = dayjs(lastBookingTime);
  const hoursSinceLastBooking = currentTime.diff(lastBookingTimestamp, 'hour');
  return hoursSinceLastBooking;
};

export const getMinutesSinceLastBooking = (lastBookingTime: string) => {
  const currentTime = dayjs();
  const lastBookingTimestamp = dayjs(lastBookingTime);
  const minutesSinceLastBooking = currentTime.diff(lastBookingTimestamp, 'minutes');
  return minutesSinceLastBooking > 0 ? minutesSinceLastBooking : 1;
};

export const getSocialProofBannerTitle = (
  lastBookingTime: string | null,
  totalActivityBookings: number,
): string | null => {
  if (!lastBookingTime) return null;
  const hoursSinceLastBooking = getHoursSinceLastBooking(lastBookingTime);
  const minutesSinceLastBooking = getMinutesSinceLastBooking(lastBookingTime);
  const minutesBookedTitle = `Last booked just ${minutesSinceLastBooking} minute${
    minutesSinceLastBooking > 1 ? 's' : ''
  } ago - don't miss out!`;
  const hourBookedTitle = `Last booked just ${hoursSinceLastBooking} hour${
    hoursSinceLastBooking > 1 ? 's' : ''
  } ago - don't miss out!`;
  const bookingCountTitle = `In demand! Join ${roundUpToClosestIncrementOf5(
    totalActivityBookings,
  )}+ others who've already booked!`;
  if (hoursSinceLastBooking < 1) {
    return minutesBookedTitle;
  } else if (hoursSinceLastBooking <= 6) {
    return hourBookedTitle;
  } else if (totalActivityBookings >= 5) {
    return bookingCountTitle;
  } else if (hoursSinceLastBooking <= 48) {
    return hourBookedTitle;
  } else {
    return null;
  }
};

// export const getTicketPriceSummary = (tickets: ActivityTicketType): string => {
export const getTicketPriceSummary = (allTickets: ActivityTicket[]): string => {
  // get all unique ticket prices.
  const allAvailableTicketPrices = Array.from(
    new Set(allTickets.filter((ticket) => ticket.isAvailable).map((ticket) => ticket.price)),
  );

  // if all tickets are same price, return.
  if (allAvailableTicketPrices.length === 1) {
    return allAvailableTicketPrices[0] === 0
      ? 'from Free'
      : `from ${formatPenceToPounds(allAvailableTicketPrices[0])}`;
  }

  const sortedByPrice = allAvailableTicketPrices.sort((a, b) => {
    return a - b;
  });

  const highestPrice = sortedByPrice[sortedByPrice.length - 1];
  const lowestPrice = sortedByPrice[0];

  if (lowestPrice === 0) return `from Free - ${formatPenceToPounds(highestPrice)}`;

  return `from ${formatPenceToPounds(lowestPrice)} - ${formatPenceToPounds(highestPrice)}`;
};
