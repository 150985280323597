import { ActionIcon, Box, Flex, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import classNames from 'classnames';
import { formatPenceToPounds } from 'utils/formatPrice';
import { Product } from 'types';
import { ActivityTicket } from 'interfaces';
import { PricingPolicyEnum } from 'enums';
import { trackAction, Actions } from 'utils/amplitude';
import BookNowButton from 'components/BookNowButton/BookNowButton';
import { useMediaQuery } from '@mantine/hooks';
import { useBasket } from 'context/BasketContext';
import classes from './TicketTypesCard.module.scss';
import { Plus, Minus } from '@phosphor-icons/react';

interface ITicketTypesCard {
  ticket: ActivityTicket;
  selectedSession: Product | null;
  addTicket(ticketId: string): void;
  removeTicket(ticketId: string): void;
  ticketCount: number;
  noSpacesLeft: boolean;
  isSubscription: boolean;
  loading: boolean;
  handleBookNow: (ticket: ActivityTicket) => void;
}

const TicketTypesCard: React.FC<ITicketTypesCard> = ({
  ticket,
  selectedSession,
  noSpacesLeft,
  isSubscription,
  loading,
  handleBookNow,
  ticketCount,
  addTicket,
  removeTicket,
}) => {
  const { basketItems } = useBasket();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const theme = useMantineTheme();
  const attendeeCount = ticketCount * ticket.capacity;

  const isFreeTicket = ticket.pricingPolicy === PricingPolicyEnum.FREE;
  const isPerSessionTicket = ticket.pricingPolicy === PricingPolicyEnum.PER_SESSION;
  const isFullBlockTicket = ticket.pricingPolicy === PricingPolicyEnum.BLOCK_FULL_PRICE;

  const totalTicketValue =
    basketItems && basketItems.length > 0 && selectedSession?.singleSessionDate
      ? ticket.price * ticketCount * basketItems.length
      : ticket.price * ticketCount;

  const getSessionFormat = (sessionCount: number) => {
    return sessionCount > 1 ? 'sessions' : 'session';
  };

  const getTicketPrice = () => {
    if (!selectedSession) return;

    if (isPerSessionTicket) {
      return `${formatPenceToPounds(
        totalTicketValue * selectedSession?.numberOfSessionsAvailable,
      )}`;
    }

    return `${formatPenceToPounds(totalTicketValue)}`;
  };

  const getPricePerSessionLabelSubscription = (subPriceButton = false) => {
    if (!selectedSession) return;

    const ticketPrice = formatPenceToPounds(ticket.price);

    return subPriceButton ? `${ticketPrice} / mth` : `${ticketPrice} / MONTH`;
  };

  const getPricePerSessionLabel = () => {
    if (!selectedSession) return;

    if (isFullBlockTicket) {
      return (
        <>
          <Text fw={600} pr={4}>
            {formatPenceToPounds(ticket.price)}{' '}
          </Text>{' '}
          / Set Price
        </>
      );
    }

    if (selectedSession?.singleSessionDate && basketItems && basketItems.length > 0) {
      const totalBasketPrice = !isFreeTicket
        ? formatPenceToPounds(ticket.price * basketItems.length)
        : '';

      return (
        <Flex data-testid={'basket-price-session'} align={'center'}>
          <Text fw={600} pr={4}>
            {totalBasketPrice}
          </Text>{' '}
          ({basketItems.length} {getSessionFormat(basketItems.length)})
        </Flex>
      );
    }

    const numberOfSessions = selectedSession?.numberOfSessionsAvailable;

    const totalSessionsPrice = !isFreeTicket
      ? formatPenceToPounds(ticket.price * numberOfSessions)
      : '';

    return (
      <Flex data-testid={'basket-price-block'} align="center">
        <Text fw={600} pr={4}>
          {totalSessionsPrice}
        </Text>
        ({numberOfSessions} {getSessionFormat(numberOfSessions)})
      </Flex>
    );
  };

  return (
    <Box
      className={classNames(classes.card, {
        [classes.unavailable]: !ticket.isAvailable,
        [classes.subscriptionCard]: isSubscription,
      })}
      py={isMobile ? 24 : 16}
    >
      <div className={classes.ticketInfoWrapper}>
        <Text component="h2" className={classes.ticketName}>
          {ticket.name}
        </Text>
        <Flex
          className={classNames(classes.ticketPrice, { [classes.ticketPriceSubs]: isSubscription })}
          py="xs"
          data-testid="ticket-price"
        >
          {ticket.price === 0 && (
            <Text component="p" className={classes.freeTicket}>
              Free
            </Text>
          )}
          {isSubscription ? getPricePerSessionLabelSubscription(false) : getPricePerSessionLabel()}
        </Flex>
        <Text className={classes.ticketDescription}>{ticket.description}</Text>
      </div>

      {!ticket.isAvailable && (
        <Group className={classes.inputWrapper}>
          <Text>UNAVAILABLE</Text>
        </Group>
      )}

      {ticket.isAvailable && isSubscription && (
        <Group className={classes.inputWrapper}>
          <BookNowButton
            onClick={() => {
              handleBookNow(ticket);
              trackAction(Actions.SUB_SUBSCRIBE);
            }}
            loading={loading}
            mt="sm"
            px="xl"
            mb={isMobile ? 'sm' : 0}
            size="md"
            radius={60}
            subPriceButton={true}
          >
            Subscribe{' '}
            {selectedSession && (
              <span className={classes.bookButtonPrice}>
                {getPricePerSessionLabelSubscription(true)}
              </span>
            )}
          </BookNowButton>

          <Stack className={classes.cancelWrapper} gap={0} align="center" justify="center">
            <Text className={classes.cancelCopyBold}>Cancel anytime with provider.</Text>
            <Text className={classes.cancelCopy}>Subject to provider T&Cs</Text>
          </Stack>
        </Group>
      )}

      {ticket.isAvailable && !isSubscription && (
        <Flex
          direction={isMobile ? 'row' : 'column'}
          gap={isMobile ? 12 : 0}
          className={classes.inputWrapper}
        >
          <div className={classes.buttonWrapper}>
            <ActionIcon
              title="minus-button"
              disabled={ticketCount <= 0}
              size={36}
              variant="default"
              onClick={() => {
                removeTicket(ticket.id);
                trackAction(Actions.MINUS_BASKET);
              }}
              className={classes.minusButton}
              mr={isMobile ? 4 : 8}
            >
              <Minus
                size={18}
                color={ticketCount > 1 ? `${theme.colors.pink[5]}` : '#4c5471'}
                weight="bold"
              />
            </ActionIcon>
            <ActionIcon
              title="add-button"
              disabled={noSpacesLeft}
              size={36}
              variant="default"
              className={classes.addButton}
              onClick={() => {
                addTicket(ticket.id);
                trackAction(Actions.ADDITION_BASKET);
              }}
            >
              <Plus size={18} color="white" weight="bold" />
            </ActionIcon>
          </div>

          <Group className={classes.attendeesLabel}>
            <Text fw="bold" pl={'xs'}>
              {attendeeCount}
            </Text>
            <Text className={classes.growLabel}>
              {attendeeCount > 1 ? 'Attendees' : 'Attendee'}
            </Text>
            {ticket.price > 0 ? (
              // TODO: fix calculation for block tickets
              <Text pr={'xs'} fw="600">
                {getTicketPrice()}
              </Text>
            ) : (
              <Text className={classes.freeTicket} fw={700} fs={'lg'} pr={'xs'}>
                Free
              </Text>
            )}
          </Group>
        </Flex>
      )}
    </Box>
  );
};

export default TicketTypesCard;
