import { Button, List } from '@mantine/core';
import { Product } from 'types';
import BasketSessionsListItem from './BasketSessionsListItem';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './BasketPopup.module.scss';

interface IBasketSessionsListProps {
  basketItems: Product[];
  hideEndTimes?: boolean;
}

const BasketSessionsList: React.FC<IBasketSessionsListProps> = ({
  basketItems,
  hideEndTimes = false,
}) => {
  const [showAll, setShowAll] = useState(false);

  const sortedBasketItems = useMemo(() => {
    return [...basketItems].sort((a, b) => {
      // Returns 0 is they are the same date
      const dateComparison = (a.singleSessionDate || '').localeCompare(b.singleSessionDate || '');

      // If the dates are different, sort by date
      if (dateComparison !== 0) {
        return dateComparison;
      } else {
        // If the dates are the same, sort by start time
        return dayjs(a.startTime, 'HH:mm').isBefore(dayjs(b.startTime, 'HH:mm')) ? -1 : 1;
      }
    });
  }, [basketItems]);

  return (
    <>
      <List
        size="md"
        listStyleType="none"
        style={{ width: '100%' }}
        classNames={{
          itemWrapper: classes.listItemWrapper,
        }}
        spacing="md"
      >
        {showAll
          ? sortedBasketItems.map((product, index) => (
              <BasketSessionsListItem key={index} product={product} hideEndTimes={hideEndTimes} />
            ))
          : sortedBasketItems
              .slice(0, 3)
              .map((product, index) => (
                <BasketSessionsListItem key={index} product={product} hideEndTimes={hideEndTimes} />
              ))}
      </List>
      {sortedBasketItems.length > 3 && (
        <Button
          onClick={() => {
            setShowAll((prev) => !prev);
            if (!showAll) {
              return trackAction(Actions.BASKET_EXPAND);
            }
          }}
          radius={6}
          w="100%"
          mt={8}
        >
          {showAll ? 'Show less' : `Show ${sortedBasketItems.length - 3} more`}
        </Button>
      )}
    </>
  );
};

export default BasketSessionsList;
