import {
  Modal,
  ModalProps,
  Group,
  Text,
  Box,
  ActionIcon,
  Title,
  Affix,
  Notification,
  Divider,
  useMantineTheme,
  Flex,
  Spoiler,
} from '@mantine/core';
import { Product, SelectedTickets } from 'types';
import { ActivityTicket } from 'interfaces';
import { ActivityTypeEnum, PricingPolicyEnum, SocialProofLabelEnum } from 'enums';
import { useMediaQuery } from '@mantine/hooks';
import TicketTypesCard from 'components/TicketTypesCard/TicketTypesCard';
import dayjs from 'dayjs';
import { trackAction, Actions } from 'utils/amplitude';
import { useEffect, useMemo, useState } from 'react';
import { formatPenceToPounds } from 'utils/formatPrice';
// import { showTimesVaryBanner } from 'components/ActivityDetails/TimesAndAvailability/utils';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useBasket } from 'context/BasketContext';
import classes from './TicketTypesSelectionModal.module.scss';
import classNames from 'classnames';
import { Button } from 'components/ui';
import { Info, X } from '@phosphor-icons/react';
// import { ClockCounterClockwise } from '@phosphor-icons/react';

dayjs.extend(advancedFormat);

interface ITicketTypesSelectionModal extends ModalProps {
  onClose(): void;
  selectedSession: Product | null;
  handleBookNow: (ticket?: ActivityTicket) => void;
  name?: string;
  selectedTickets: SelectedTickets[];
  setSelectedTickets(value: SelectedTickets[]): void;
  loading: boolean;
  activityType?: ActivityTypeEnum;
  tickets: ActivityTicket[];
  supplierId: string;
  socialProofBadge?: SocialProofLabelEnum | null;
  activityId?: string;
  fromBasket?: boolean;
  supplierName?: string;
}

const TicketTypesSelectionModal: React.FC<ITicketTypesSelectionModal> = ({
  onClose,
  selectedSession,
  handleBookNow,
  name,
  selectedTickets,
  setSelectedTickets,
  loading,
  activityType,
  tickets,
  supplierId,
  socialProofBadge,
  activityId,
  supplierName,

  //   flag to determine if the modal is being rendered from the basket popup
  fromBasket,
  ...modalProps
}) => {
  const theme = useMantineTheme();
  const {
    basketItems,
    maximumTicketQuantity: basketMaximumTicketQuantity,
    activityDetails,
    selectedClass,
  } = useBasket();
  const isSubscription = activityType === ActivityTypeEnum.SUBSCRIPTION;
  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const [totalTicketsQuantity, setTicketsTotalQuantity] = useState<number>(0);
  const [noSpacesLeft, setNoSpacesLeft] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(noSpacesLeft);
  const [showMaxTicketsBanner, setShowMaxTicketsBanner] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const hideEndTimes = activityDetails?.activity.hideEndTimes;

  const onAddTicket = (ticketId: string) => {
    const selectedTicket = tickets.find((t) => t.id === ticketId);
    if (!selectedTicket) {
      return;
    }
    const newTicket: SelectedTickets = {
      ticketId: selectedTicket.id,
      ticketPrice: selectedTicket.price,
      capacity: selectedTicket.capacity,
      pricingPolicy: selectedTicket.pricingPolicy,
    };
    const newTickets = [...selectedTickets, newTicket];
    setSelectedTickets(newTickets);
  };

  const onRemoveTicket = (ticketId: string) => {
    const ticketIndexToRemove = selectedTickets.findIndex((t) => t.ticketId === ticketId);
    if (ticketIndexToRemove < 0) {
      return;
    }
    const copyOfSelectedTickets = [...selectedTickets];
    copyOfSelectedTickets.splice(ticketIndexToRemove, 1);
    setSelectedTickets(copyOfSelectedTickets);
  };

  // const parseFullSessionDateString = (session: Product | null) => {
  //   if (!session) return '';

  //   if (session?.singleSessionDate) {
  //     const dayjsDate = dayjs(session.singleSessionDate);
  //     const year = dayjsDate.format('YYYY');
  //     const dayOfWeek = dayjsDate.format('dddd');

  //     const timeString = `${dayOfWeek}, ${session?.dateRange} ${year}, ${session.startTime}`;

  //     return hideEndTimes ? timeString : `${timeString} - ${session.endTime}`;
  //   }

  //   if (session.blockFirstSessionDate && session.blockLastSessionDate) {
  //     const lastSessionYear = dayjs(session?.blockLastSessionDate).format('YYYY');

  //     const date = `${session?.dateRange} ${lastSessionYear}`;
  //     const times = hideEndTimes ? session.startTime : `${session.startTime} - ${session.endTime}`;

  //     return `${date}, ${!isMobile ? `${times}` : ''}`;
  //   }
  // };

  const getTotalPrice = () => {
    if (!selectedTickets || !selectedSession) return;

    const multiplier =
      selectedSession?.singleSessionDate && basketItems && basketItems.length > 0
        ? basketItems.length
        : selectedSession?.numberOfSessionsAvailable;

    const calcPrice = (ticket: SelectedTickets) => {
      if (ticket.pricingPolicy === PricingPolicyEnum.BLOCK_FULL_PRICE) {
        return ticket.ticketPrice;
      }
      return ticket.ticketPrice * multiplier;
    };
    const totalTickets = selectedTickets.reduce(
      (total: number | null, ticket) => Number(total) + calcPrice(ticket),
      null,
    );

    if (!totalTickets && totalTickets !== 0) return '£0.00';

    return totalTickets === 0 ? 'Free' : formatPenceToPounds(totalTickets);
  };

  const getTicketsContainerHeight = () => {
    if (isMobile) {
      if (showModal) return 'calc(100% - 8rem)';

      return 'calc(100% - 5rem)';
    }
  };

  const getTicketCount = (ticketId: string): number => {
    const matchingTickets = selectedTickets.filter((t) => t.ticketId === ticketId);
    return matchingTickets.length;
  };

  const ticketHasSpaces = (ticketCapacity: number): boolean => {
    const spotsLeft = selectedSession?.spotsLeft || 0;
    return totalTicketsQuantity + ticketCapacity > spotsLeft;
  };

  const sortedTickets = tickets.sort((a, b) => Number(b.isAvailable) - Number(a.isAvailable));

  useEffect(() => {
    if (selectedTickets) {
      const totalQuantity = selectedTickets.reduce((accum, curr) => accum + curr.capacity, 0);
      setTicketsTotalQuantity(totalQuantity);
    }
  }, [selectedTickets, totalTicketsQuantity, setTicketsTotalQuantity]);

  useEffect(() => {
    if (!selectedSession) return;
    if (totalTicketsQuantity >= selectedSession?.spotsLeft) {
      setNoSpacesLeft(true);
      setShowModal(true);
    } else {
      setNoSpacesLeft(false);
      setShowModal(false);
    }
  }, [totalTicketsQuantity, selectedSession]);

  //   Handle displaying max. tickets react banner
  useEffect(() => {
    if (basketMaximumTicketQuantity && totalTicketsQuantity >= basketMaximumTicketQuantity) {
      setNoSpacesLeft(true);
      setShowMaxTicketsBanner(true);
    } else {
      setNoSpacesLeft(false);
      setShowMaxTicketsBanner(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTicketsQuantity, basketMaximumTicketQuantity]);

  const spoilerSplitDates = useMemo(() => {
    const cutOff = 3;
    return fromBasket && basketItems
      ? [basketItems.slice(0, cutOff), basketItems.slice(cutOff, basketItems.length)]
      : selectedSession?.allBlockDates
      ? [
          selectedSession?.allBlockDates.slice(0, cutOff),
          selectedSession?.allBlockDates.slice(cutOff, selectedSession?.allBlockDates.length),
        ]
      : [[]];
  }, [selectedSession?.allBlockDates, fromBasket, basketItems]);

  return (
    <>
      <Modal
        {...modalProps}
        size={1076}
        classNames={{
          root: classes.modal,
          body: classes.modalBody,
          content: classes.modalBodyContent,
          inner: classes.modalInner,
        }}
        centered
        padding={0}
        onClose={onClose}
        fullScreen={isMobile}
        lockScroll={true}
      >
        <Group
          align="stretch"
          className={classes.container}
          wrap={'nowrap'}
          h={getTicketsContainerHeight()}
        >
          <ActionIcon
            onClick={onClose}
            className={classes.closeButton}
            variant="filled"
            aria-label="close-button"
          >
            <X
              size={20}
              color="white"
              weight="bold"
              onClick={() => trackAction(Actions.TICKETS_CLOSE)}
            />
          </ActionIcon>

          <div className={classes.modalContent}>
            <Box
              component="section"
              className={classes.activityHeader}
              aria-label="activity header"
            >
              {!isSubscription && (
                <Title order={1} mb={8}>
                  {supplierName}
                </Title>
              )}
              <Title order={2}>{name}</Title>
            </Box>

            <Divider mt={8} mx={isMobile ? 24 : 0} />

            <Flex
              className={classNames(classes.ticketsWrapper, {
                [classes.ticketsWrapperNoAffix]: isSubscription,
                [classes.ticketsWrapperNoErrorMassage]: showErrorMessage,
              })}
              aria-label="ticket(s) wrapper"
              onClick={() => setShowErrorMessage(false)}
              direction={isMobile ? 'column' : 'row'}
            >
              <Flex
                maw={isMobile ? 'auto' : 220}
                className={classes.sessionInformation}
                direction={'column'}
                gap={8}
                mt={isMobile ? 8 : 32}
              >
                <Text fw={700} size="sm">
                  {!isSubscription && <>{selectedClass?.name}</>}
                  {isSubscription && selectedSession && selectedSession.weekdays && (
                    <>
                      {selectedSession?.weekdays[0]},{' '}
                      {hideEndTimes
                        ? selectedSession.startTime
                        : `${selectedSession.startTime} - ${selectedSession.endTime}`}
                    </>
                  )}
                </Text>
                <Text c={theme.colors.gray[6]} fw={600} size="xs">
                  Selected session(s):
                </Text>
                <Flex direction={'column'}>
                  <Spoiler
                    maxHeight={spoilerSplitDates[1]?.length > 0 ? 65 : 9999}
                    showLabel={`View more`}
                    hideLabel="See less"
                    classNames={{
                      control: classes.spoilerControl,
                    }}
                    onClick={() => {
                      trackAction(Actions.VIEW_DATES_DETAILS);
                    }}
                  >
                    {/* {dates.map(getDateListItem)} */}
                    {!fromBasket &&
                      !isSubscription &&
                      selectedSession?.allBlockDates?.map((session) => {
                        if (!session.isInPast) {
                          return (
                            <Flex className={classes.selectedClassesTimes}>
                              <Text>{dayjs(session.date).format('DD MMM')}</Text>
                              {hideEndTimes ? (
                                <Text ml={8}>{session.startTime}</Text>
                              ) : (
                                <Text ml={8}>
                                  {session.startTime} - {session.endTime}
                                </Text>
                              )}
                            </Flex>
                          );
                        }
                      })}

                    {fromBasket &&
                      basketItems.length > 0 &&
                      basketItems.map((session) => (
                        <Flex className={classes.selectedClassesTimes}>
                          <Text>{session.dateRange}</Text>
                          {hideEndTimes ? (
                            <Text ml={8}>{session.startTime}</Text>
                          ) : (
                            <Text ml={8}>
                              {session.startTime} - {session.endTime}
                            </Text>
                          )}
                        </Flex>
                      ))}
                  </Spoiler>
                  {isSubscription && selectedSession?.weekdays && (
                    <Text size="sm" fw={600}>
                      Next:{' '}
                      {dayjs(selectedSession.subscriptionNextSessionDate).format('Do MMMM YYYY')}
                    </Text>
                  )}
                </Flex>
              </Flex>

              <Flex
                direction={'column'}
                className={classes.ticketsColumn}
                gap={24}
                mt={isMobile ? 0 : 12}
              >
                {sortedTickets.map((ticket: ActivityTicket) => (
                  <TicketTypesCard
                    isSubscription={isSubscription}
                    selectedSession={selectedSession}
                    ticket={ticket}
                    key={ticket.id}
                    addTicket={onAddTicket}
                    removeTicket={onRemoveTicket}
                    ticketCount={getTicketCount(ticket.id)}
                    noSpacesLeft={ticketHasSpaces(ticket.capacity)}
                    loading={loading}
                    handleBookNow={handleBookNow}
                  />
                ))}
              </Flex>
            </Flex>

            {showModal && !isMobile && !fromBasket && (
              <Notification
                classNames={{
                  icon: classes.notificationIcon,
                  root: classes.notificationRoot,
                  title: classes.notificationTitle,
                  closeButton: classes.notificationCloseBtn,
                }}
                icon={<Info size={32} color={theme.colors.blue[8]} />}
                onClose={() => {
                  setShowModal(false);
                }}
                title="There are no more spaces on this session to book."
              />
            )}

            {showMaxTicketsBanner && !isMobile && fromBasket && (
              <Notification
                classNames={{
                  icon: classes.notificationIcon,
                  root: classes.notificationRoot,
                  title: classes.notificationTitle,
                  closeButton: classes.notificationCloseBtn,
                }}
                icon={<Info size={32} color={theme.colors.blue[8]} />}
                onClose={() => {
                  setShowMaxTicketsBanner(false);
                }}
                title="You've reached the maximum number of tickets you can add."
              />
            )}

            {!isSubscription && (
              <Group justify={'flex-end'} mr={36} mt={8} mb={50} className={classes.totalCost}>
                <Text mr={32} fw={700}>
                  Total cost:
                </Text>
                <Text
                  fw={700}
                  className={classNames(classes.totalCostText, {
                    [classes.totalCostFree]: getTotalPrice() === 'Free',
                  })}
                >
                  {getTotalPrice()}
                </Text>
              </Group>
            )}

            {!isSubscription && isMobile && (
              <Affix position={{ bottom: 0, left: 0, right: 0 }} zIndex={201}>
                <Group gap={0} className={classes.affixContainer}>
                  {showModal && !fromBasket && (
                    <Notification
                      classNames={{
                        icon: classes.notificationIcon,
                        root: classes.notificationRoot,
                        title: classes.notificationTitle,
                        closeButton: classes.notificationCloseBtn,
                      }}
                      radius={0}
                      icon={<Info size={32} color={theme.colors.blue[8]} />}
                      onClose={() => {
                        setShowModal(false);
                      }}
                      title="There are no more spaces on this session to book."
                    />
                  )}

                  {showMaxTicketsBanner && fromBasket && (
                    <Notification
                      classNames={{
                        icon: classes.notificationIcon,
                        root: classes.notificationRoot,
                        title: classes.notificationTitle,
                        closeButton: classes.notificationCloseBtn,
                      }}
                      radius={0}
                      icon={<Info size={32} color={theme.colors.blue[8]} />}
                      onClose={() => {
                        setShowMaxTicketsBanner(false);
                      }}
                      title="You've reached the maximum number of tickets you can add."
                    />
                  )}

                  <div className={classes.bookNowContainer}>
                    <Button
                      fullWidth={true}
                      onClick={() => {
                        if (selectedTickets && selectedTickets.length > 0) {
                          handleBookNow();
                        } else {
                          setShowErrorMessage(true);
                        }
                      }}
                      data-testid="book-now-button"
                    >
                      Next
                    </Button>
                    {showErrorMessage && (
                      <Text m={0} size="sm" c={theme.colors.red[6]} ta="center">
                        Please add ticket(s) to proceed
                      </Text>
                    )}
                  </div>
                </Group>
              </Affix>
            )}

            {!isSubscription && !isMobile && (
              <div className={classes.bookNowContainer}>
                <Button
                  fullWidth={false}
                  onClick={() => {
                    if (selectedTickets && selectedTickets.length > 0) {
                      handleBookNow();
                    } else {
                      setShowErrorMessage(true);
                    }
                  }}
                  data-testid="book-now-button"
                  miw={245}
                  className={classes.nextBtn}
                >
                  <Text fw={700} mr={4}>
                    Proceed to checkout
                  </Text>
                </Button>
                {showErrorMessage && (
                  <Text m={0} size="sm" pt={4} c={theme.colors.red[6]}>
                    Please add ticket(s) to proceed
                  </Text>
                )}
              </div>
            )}
          </div>
        </Group>
      </Modal>
    </>
  );
};

export default TicketTypesSelectionModal;
