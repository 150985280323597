import { Product } from 'types';
import { Box, Text, Group, Flex } from '@mantine/core';
import { trackAction, Actions } from 'utils/amplitude';
import {
  getSpotsLeftText,
  showTimesVaryBanner,
} from 'components/ActivityDetails/TimesAndAvailability/utils';
import BlockBookingDates from 'components/ActivityDetails/TimesAndAvailability/BlockBookingDates/BlockBookingDates';
import { Fragment } from 'react';
import { PebbleRadio, PebbleRadioGroup } from 'components/ui';
import classes from './RadioTimePicker.module.scss';
import classNames from 'classnames';
import { ClockClockwise } from '@phosphor-icons/react';

interface IRadioTimePicker {
  products: Product[];
  selectedSession: Product | null;
  onSelect(session: Product): void;
  heading?: string;
  showViewAllDatesButtons?: boolean;
  showDatesByWeek?: boolean;
  nextSessionSubheading?: string;
  setUserSelectedRadio?(val: boolean): void;
  hideEndTimes: boolean;
}

const RadioTimePicker: React.FC<IRadioTimePicker> = ({
  products,
  selectedSession,
  onSelect,
  heading,
  showViewAllDatesButtons,
  showDatesByWeek = false,
  nextSessionSubheading,
  setUserSelectedRadio,
  hideEndTimes,
}) => {
  return (
    <>
      {heading && (
        <Box className={classes.header}>
          <Text className={classes.headerText}>{heading}</Text>
          {nextSessionSubheading && (
            <Text pb={8} className={classes.subheaderText}>
              {nextSessionSubheading}
            </Text>
          )}
        </Box>
      )}

      <PebbleRadioGroup
        value={selectedSession?.checkoutValue ?? ''}
        onChange={(value) => {
          if (setUserSelectedRadio) {
            setUserSelectedRadio(true);
          }
          const sessionById = products.find((product) => product.checkoutValue === value);
          if (sessionById) {
            onSelect(sessionById);
          }
          trackAction(Actions.TIME_DETAILS);
        }}
      >
        <Group mt="32">
          {products.map((session) => {
            return (
              <Fragment key={session.checkoutValue}>
                <PebbleRadio
                  key={session.checkoutValue}
                  value={session.checkoutValue}
                  label={
                    <>
                      <div className={classes.labelWrapper}>
                        <Text fw="700">
                          {hideEndTimes
                            ? session.startTime
                            : `${session.startTime} - ${session.endTime}`}
                        </Text>

                        <Text
                          component="div"
                          fw="600"
                          className={classNames({
                            [classes.waitlist]: session.spotsLeft === 0,
                          })}
                        >
                          {getSpotsLeftText(session.spotsLeft)}
                        </Text>
                      </div>
                      {session?.allBlockDates &&
                        showTimesVaryBanner(
                          session.startTime,
                          session.endTime,
                          session.allBlockDates,
                        ) && (
                          <Flex align="center" className={classes.timeVaryBadge}>
                            <Text fw={700} size="xs" mr="2px">
                              Times vary
                            </Text>
                            <ClockClockwise size={16} weight="bold" />
                          </Flex>
                        )}
                    </>
                  }
                />
                <div className={classes.viewAllDates}>
                  {showViewAllDatesButtons && (
                    <BlockBookingDates
                      startTime={session.startTime}
                      endTime={session.endTime}
                      blockDates={session.allBlockDates || []}
                      showDatesByWeek={showDatesByWeek}
                      hideTimes={hideEndTimes}
                    />
                  )}
                </div>
              </Fragment>
            );
          })}
        </Group>
      </PebbleRadioGroup>
    </>
  );
};

export default RadioTimePicker;
