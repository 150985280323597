import { Box, Flex } from '@mantine/core';
import { ClassesType, Product, ProductsByDate } from 'types';
import { useEffect, useState } from 'react';
import { groupSessionsByMonth } from 'components/ActivityDetails/TimesAndAvailability/utils';
import dayjs from 'dayjs';
import { trackAction, Actions } from 'utils/amplitude';
import { DatePicker, DatePickerValue } from '@mantine/dates';
import SessionPicker from './SessionPicker/SessionPicker';
import classes from './SessionsByDatePicker.module.scss';

interface ISessionsByDatePicker {
  sessionsByDate: ProductsByDate[];
  selectedSession: Product | null;
  onSelect(session: Product): void;
  onClearSession(): void;
  blockBookingTabActive: boolean;
  setSelectedDatePicker(value: string | undefined): void;
  hasAvailableTickets: boolean;
  hideEndTimes: boolean;
  selectedClass: ClassesType;
}

type ActiveMonthAndDate = {
  monthIndex: number;
  date: string | undefined;
};

const getDateOfFirstSessionInMonth = (
  month: string,
  sessions: Partial<Record<string, ProductsByDate[]>>,
) => {
  return sessions[month]?.[0]?.date;
};

const SessionsByDatePicker: React.FC<ISessionsByDatePicker> = ({
  sessionsByDate,
  selectedSession,
  onClearSession,
  blockBookingTabActive,
  setSelectedDatePicker,
  hasAvailableTickets,
  hideEndTimes,
  selectedClass,
}) => {
  const sessionsGroupedByDate = groupSessionsByMonth(sessionsByDate);
  const monthLabels = Object.keys(sessionsGroupedByDate);
  const firstMonth = monthLabels[0];
  const [activeMonthAndDay, setActiveMonthAndDay] = useState<ActiveMonthAndDate>({
    monthIndex: 0,
    date: getDateOfFirstSessionInMonth(firstMonth, sessionsGroupedByDate),
  });
  const sessionsByActiveMonth = sessionsGroupedByDate[monthLabels[activeMonthAndDay.monthIndex]];

  const minDate = new Date(sessionsByDate[0].date);
  const maxDate = new Date(sessionsByDate[sessionsByDate.length - 1].date);
  const getSessionDatesByMonth = sessionsByActiveMonth?.map((session) => session.date);
  const isDateExcluded = (date: Date): boolean => {
    if (getSessionDatesByMonth?.includes(dayjs(date).format('YYYY-MM-DD'))) {
      return false;
    }

    return true;
  };

  const handleMonthChange = (date: Date) => {
    //isNextMonth compare new month with the first filtered session for currently displayed month

    const isNextMonth = dayjs(date).isAfter(dayjs(sessionsByActiveMonth?.[0].date));
    const updatedMonthIndex = isNextMonth
      ? activeMonthAndDay.monthIndex + 1
      : activeMonthAndDay.monthIndex - 1;

    setActiveMonthAndDay({
      monthIndex: updatedMonthIndex,
      date: getDateOfFirstSessionInMonth(monthLabels[updatedMonthIndex], sessionsGroupedByDate),
    });

    setSelectedDatePicker(
      getDateOfFirstSessionInMonth(monthLabels[updatedMonthIndex], sessionsGroupedByDate),
    );

    if (selectedSession) {
      onClearSession();
    }
  };

  const isDaySoldOut = (date: Date) => {
    const productsByDate = sessionsByActiveMonth?.find(
      (sessions) => sessions.date === dayjs(date).format('YYYY-MM-DD'),
    )?.products;
    return productsByDate?.every((product) => {
      return product.spotsLeft === 0;
    });
  };

  const getDayClass = (date: Date) => {
    if (dayjs(date).format('YYYY-MM-DD') === activeMonthAndDay.date) {
      return classes.selectedDay;
    }
    if (getSessionDatesByMonth?.includes(dayjs(date).format('YYYY-MM-DD'))) {
      return classes.available;
    }
    if (isDaySoldOut(date)) {
      return classes.soldOutDate;
    }
  };

  useEffect(() => {
    setActiveMonthAndDay({
      monthIndex: 0,
      date: getDateOfFirstSessionInMonth(firstMonth, sessionsGroupedByDate),
    });

    setSelectedDatePicker(getDateOfFirstSessionInMonth(firstMonth, sessionsGroupedByDate));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockBookingTabActive, selectedClass]);

  return (
    <>
      <Box>
        <Flex className={classes.calendar}>
          <DatePicker
            mx={12}
            data-testid="test-id-calendar"
            onChange={(value: DatePickerValue) => {
              const formattedValue = dayjs(value).format('YYYY-MM-DD');
              setActiveMonthAndDay({
                monthIndex: activeMonthAndDay.monthIndex,
                date: formattedValue,
              });

              setSelectedDatePicker(formattedValue);

              if (selectedSession && selectedSession.singleSessionDate !== formattedValue) {
                onClearSession();
              }
              trackAction(Actions.DATE_DETAILS);
            }}
            date={dayjs(activeMonthAndDay.date).toDate()}
            onNextMonth={handleMonthChange}
            onPreviousMonth={handleMonthChange}
            getDayProps={(date) => {
              return {
                className: getDayClass(date),
                selected: false,
              };
            }}
            withCellSpacing={false}
            weekendDays={[]}
            classNames={{
              calendarHeader: classes.calendarHeader,
              calendarHeaderControl: classes.calendarPagination,
              calendarHeaderLevel: classes.calendarMonth,
              weekday: classes.weekday,
              day: classes.dayCell,
            }}
            maxLevel={'month'}
            excludeDate={isDateExcluded}
            ariaLabels={{
              nextMonth: 'next-month-button',
              previousMonth: 'previous-month-button',
            }}
            defaultDate={minDate}
            minDate={minDate}
            maxDate={maxDate}
            size={'md'}
            hideOutsideDates={true}
          />
        </Flex>
      </Box>
      {activeMonthAndDay.date && (
        <>
          <SessionPicker
            products={
              sessionsByActiveMonth?.find((sessions) => sessions.date === activeMonthAndDay.date)
                ?.products || []
            }
            hasAvailableTickets={hasAvailableTickets}
            hideEndTimes={hideEndTimes}
          />
        </>
      )}
    </>
  );
};

export default SessionsByDatePicker;
