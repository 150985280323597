import { AddOnValue, Addons, PerSessionAddonList } from 'interfaces';

export const getAddOnInput = (addOnFormValues: AddOnValue[] | undefined) => {
  return addOnFormValues
    ?.map((attendee: AddOnValue) => ({
      attendeeId: attendee.attendeeId,
      perSession: attendee.perSession
        // Filter out sessions that have all unchecked items
        .filter((perSession) => perSession.selectedSessions.some((session) => session.checked))
        .map((perSession) => ({
          addonOptionId: perSession.addonOptionId,
          // Transform selectedSessions into an array of IDs
          selectedSessionIds: perSession.selectedSessions
            .filter((session) => session.checked)
            .map((session) => session.id),
          note: perSession.note,
        })),
      perBooking: attendee.perBooking
        .filter((item) => item.quantity > 0)
        .map((item) => ({
          addonOptionId: item.addonOptionId,
          quantity: item.quantity,
          note: item.note,
        })),
    }))
    .filter((attendee) => attendee.perSession.length > 0 || attendee.perBooking.length > 0);
};

export const initialSessions = (session: PerSessionAddonList) => {
  const availableSessions = session.addonOption.availableSessions ?? [];
  return availableSessions.map((s) => {
    return {
      id: s.id,
      checked: false,
    };
  });
};

export const getExistingSelectedSessions = (session: PerSessionAddonList) => {
  const availableSessions = session.addonOption.availableSessions ?? [];
  return availableSessions.map((availableSession) => {
    const isChecked = session.selectedSessions.some(
      (selectedSession) => selectedSession.id === availableSession.id,
    );
    return {
      id: availableSession.id,
      checked: isChecked,
    };
  });
};

export const totalPrice = (
  addOnIndex: number,
  sessionIndex: number,
  session: PerSessionAddonList,
  addOns: AddOnValue[] | undefined,
) => {
  return addOns?.[addOnIndex]?.perSession[sessionIndex].selectedSessions.reduce(
    (acc, currSession) => {
      return acc + (currSession.checked ? session.addonOption.price : 0);
    },
    0,
  );
};

export const totalAmountAdded = (
  addOnIndex: number,
  sessionIndex: number,
  addOns: AddOnValue[] | undefined,
) => {
  return addOns?.[addOnIndex]?.perSession[sessionIndex].selectedSessions.reduce(
    (acc, currSession) => {
      return acc + (currSession.checked ? 1 : 0);
    },
    0,
  );
};

export const noSessionsAreChecked = (
  addonId: string,
  attendeeId: string,
  addOns: AddOnValue[] | undefined,
) => {
  const findAttendee = addOns?.find((s) => s.attendeeId === attendeeId);

  const findAddonOption = findAttendee?.perSession.find((s) => s.addonOptionId === addonId);

  return findAddonOption?.selectedSessions.every((s) => !s.checked);
};

export const isAnySessionInEditMode = (addOn: Addons, addOns: AddOnValue[] | undefined) => {
  return addOn.perSession.some((session) => {
    const addOnId = session.addonOption.id;
    return !noSessionsAreChecked(addOnId, addOn.attendee.id, addOns);
  });
};
