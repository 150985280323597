import {
  Accordion,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import CheckoutTicketAccordionCard from 'components/CheckoutDetails/CheckoutAttendees/CheckoutTicketAccordionCard/CheckoutTicketAccordionCard';
import { BasketClassType, PreCheckoutBasketTicket } from 'interfaces';
import classes from './CheckoutAttendees.module.scss';
import { Actions, trackAction } from 'utils/amplitude';
import AboutYouSection from './AboutYouSection/AboutYouSection';
import AgeCheckModal from 'components/AgeCheckModal/AgeCheckModal';
import {
  executeScroll,
  findAttendeesOutOfAgeRange,
  formatTelephoneValue,
  getExistingTelephone,
  getTicketsWithAttendees,
  validateTelephone,
} from '../CheckoutDetailsUtils';
import { useMemo } from 'react';
import { CaretDown } from '@phosphor-icons/react';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import { isntEmpty, validateEmail, validateFullName } from '@pebble/common';
import {
  CheckoutAttendeesFormProvider,
  useCheckoutAttendeesForm,
} from 'context/CheckoutFormContext';
import getRenamedTickets from 'utils/getRenamedTickets';
import { Pebbles } from '@ui';
import SelectedSessionsList from './SelectedSessionsList/SelectedSessionsList';
import { useMediaQuery } from '@mantine/hooks';

interface ICheckoutAttendeesProps {
  userToken?: string;
  supplierMarketingConsentGiven: boolean;
}

const CheckoutAttendees: React.FC<ICheckoutAttendeesProps> = ({
  userToken,
}: ICheckoutAttendeesProps) => {
  const {
    isSubscription,
    setActiveStep,
    showAgeCheckModal,
    setShowAgeCheckModal,
    accordionOpenId,
    setAccordionOpenId,
    basket,
    steps,
    activeStep,
    erroredAccordions,
    isLoggedIn,
    basketConfirmAttendeesMutation,
    basketMutationLoading,
    scrollRef,
    eligibleAttendees,
  } = useCheckoutDetailsContext();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const { activity, tickets: basketTickets } = basket;

  const checkoutAttendeesForm = useCheckoutAttendeesForm({
    initialValues: {
      fullName: basket?.guest?.fullName || '',
      email: basket?.guest?.email || '',

      telephone: getExistingTelephone(basket?.guest?.phoneNumber),
      tickets: getTicketsWithAttendees(basket, isSubscription, eligibleAttendees),
    },
    validate: {
      email: (value) =>
        validateEmail(value) || isLoggedIn ? null : 'Field must be a valid email.',
      fullName: (value) =>
        validateFullName(value) || isLoggedIn ? null : 'Please enter your first and last name.',
      telephone: (value) =>
        validateTelephone(value) || isLoggedIn ? null : 'Please enter a valid telephone number.',
      tickets: {
        attendees: {
          id: (value) => (value && isntEmpty(value) ? null : 'Please select an attendee.'),
        },
      },
    },
  });

  // renames (adds number) repetitive instances of the same tickets
  const renamedTickets: PreCheckoutBasketTicket[] = getRenamedTickets(basketTickets);

  const saveAttendeesChanges = async () => {
    const formattedTickets = checkoutAttendeesForm.values.tickets.map((ticket) => {
      const { ticket: ticketId, attendees, subscriptionTrialSelected, id: uniqueId } = ticket;
      return {
        id: uniqueId,
        ticket: ticketId,
        attendees: attendees.map(({ id, birthDate, fullName, isAdult, isNew }) => ({
          ...(!isNew && id
            ? { id }
            : {
                birthDate: isAdult ? null : birthDate,
                fullName,
                isAdult,
              }),
        })),
        ...(isSubscription && {
          subscriptionTrialSelected,
        }),
      };
    });

    await basketConfirmAttendeesMutation({
      variables: {
        input: {
          id: basket.id,
          tickets: formattedTickets,
          ...(!isLoggedIn && {
            guest: {
              fullName: checkoutAttendeesForm.values.fullName,
              phoneNumber: formatTelephoneValue(checkoutAttendeesForm.values.telephone),
              email: checkoutAttendeesForm.values.email,
            },
          }),
        },
      },
    });
  };

  const attendeesOutOfAgeRange = findAttendeesOutOfAgeRange(
    checkoutAttendeesForm.values.tickets,
    activity.ageMonthsStart,
    activity.ageMonthsEnd,
    eligibleAttendees,
  );

  const allAgesInRange = useMemo(() => {
    return attendeesOutOfAgeRange.length === 0;
  }, [attendeesOutOfAgeRange]);

  const handleNextClick = async () => {
    const { hasErrors } = checkoutAttendeesForm.validate();

    if (!hasErrors && allAgesInRange) {
      await saveAttendeesChanges();
      executeScroll(scrollRef);
      return setActiveStep(steps[steps.indexOf(activeStep) + 1]);
    } else {
      if (erroredAccordions.length > 0) {
        setAccordionOpenId(erroredAccordions[0]);
      } else if (!hasErrors && !allAgesInRange) {
        setShowAgeCheckModal(true);
        trackAction(Actions.AGE_RANGE_WARNING);
      }
    }
  };

  if (basketMutationLoading) {
    return (
      <Center style={{ height: '60vh' }}>
        <Pebbles />
      </Center>
    );
  }

  const classAccordionItems = (basketClasses: BasketClassType[]) => {
    return basketClasses.map((item) => (
      <Accordion.Item key={item.id} value={item.id} className={classes.classCard}>
        <Accordion.Control>
          <Text fw={700} size="xl" c={theme.colors.blue[8]}>
            {item.name}
          </Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Grid>
            <Grid.Col span={isMobile ? 12 : 3}>
              <Text c={theme.colors.gray[6]} fw={600} size="xs" mb="xs">
                Selected sessions:
              </Text>
              <Box>
                <SelectedSessionsList />
              </Box>
            </Grid.Col>
            <Grid.Col span={isMobile ? 12 : 9}>
              <Accordion
                value={accordionOpenId}
                onChange={setAccordionOpenId}
                chevron={<CaretDown size={18} weight="bold" color={theme.colors.blue[8]} />}
              >
                {renamedTickets.map((_ticket, ticketIndex) => {
                  return (
                    <CheckoutTicketAccordionCard
                      key={`${_ticket.ticketName}-${_ticket.id}`}
                      id={`tickets.${ticketIndex}`}
                      ticketName={_ticket.ticketName}
                      ticketIndex={ticketIndex}
                      subscriptionTrialInfo={{
                        trialType: activity.subscriptionTrialType,
                        trialLength: activity.subscriptionTrialSessionCount,
                        trialCost: activity.subscriptionTrialPrice,
                      }}
                    />
                  );
                })}
              </Accordion>
            </Grid.Col>
          </Grid>
        </Accordion.Panel>
      </Accordion.Item>
    ));
  };
  return (
    <CheckoutAttendeesFormProvider form={checkoutAttendeesForm}>
      <Box className={classes.attendeeStepWrapper}>
        {!userToken && <AboutYouSection form={checkoutAttendeesForm} />}

        <Flex justify="space-between" mt={!userToken ? 'xl' : '0px'} align="center">
          <Title order={3} className={classes.title}>
            About your attendee(s)
          </Title>
        </Flex>
        {basket?.classes ? (
          <Accordion defaultValue={basket.classes[0].id}>
            {classAccordionItems(basket.classes)}
          </Accordion>
        ) : (
          <Accordion
            value={accordionOpenId}
            onChange={setAccordionOpenId}
            chevron={<CaretDown size={18} weight="bold" color={theme.colors.blue[8]} />}
          >
            {renamedTickets.map((_ticket, ticketIndex) => {
              return (
                <CheckoutTicketAccordionCard
                  key={`${_ticket.ticketName}-${_ticket.id}`}
                  id={`tickets.${ticketIndex}`}
                  ticketName={_ticket.ticketName}
                  ticketIndex={ticketIndex}
                  subscriptionTrialInfo={{
                    trialType: activity.subscriptionTrialType,
                    trialLength: activity.subscriptionTrialSessionCount,
                    trialCost: activity.subscriptionTrialPrice,
                  }}
                />
              );
            })}
          </Accordion>
        )}

        <AgeCheckModal
          opened={showAgeCheckModal}
          onClose={() => {
            setShowAgeCheckModal(false);
          }}
          handleConfirmAttendees={async () => {
            await saveAttendeesChanges();
            executeScroll(scrollRef);
            setActiveStep(steps[steps.indexOf(activeStep) + 1]);
          }}
          attendeesOutOfAgeRange={attendeesOutOfAgeRange}
          activity={activity}
        />
      </Box>
      <Box className={classes.nextButtonWrapper}>
        <Button onClick={handleNextClick} className={classes.nextButton} fullWidth>
          Next
        </Button>
      </Box>
    </CheckoutAttendeesFormProvider>
  );
};

export default CheckoutAttendees;
