import { Button, Divider, Flex, Text, useMantineTheme } from '@mantine/core';
import classes from './SessionSelectContent.module.scss';
import { PebbleCheckbox } from 'components/ui';
import classNames from 'classnames';
import {
  AddOnFormValues,
  AddOnSessionsList,
  AddOnValue,
  AttendeeAddonInputType,
  PerSessionAddonList,
} from 'interfaces';
import { UseFormReturnType, useForm } from '@mantine/form';
import { Fragment } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { getAddOnInput } from '../AddOnsStep.utils';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { formatPenceToPounds } from 'utils/formatPrice';
import { Actions, trackAction } from 'utils/amplitude';
dayjs.extend(advancedFormat);

interface ISessionSelectContentProps {
  session: PerSessionAddonList;
  addOnIndex: number;
  sessionIndex: number;
  addOnsForm: UseFormReturnType<AddOnFormValues>;
  handleUpdateAddOns(val: AttendeeAddonInputType[] | undefined): Promise<void>;
  closeSessionDetails: () => void;
  cancelSelectedSessionsChanges?: (addOnIndex: number, sessionIndex: number) => void;
  hideEndTimes?: boolean;
}

const SessionSelectContent: React.FC<ISessionSelectContentProps> = ({
  session,
  addOnIndex,
  sessionIndex,
  addOnsForm,
  handleUpdateAddOns,
  closeSessionDetails,
  hideEndTimes = false,
}) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const getInitialValues = () => {
    const currentValues =
      addOnsForm?.values?.addOns?.[addOnIndex].perSession[sessionIndex].selectedSessions;

    return currentValues;
  };

  const individualAddonForm = useForm({
    initialValues: { addOns: getInitialValues() },
  });

  const getPrice = () => {
    const totalPrice =
      individualAddonForm.values.addOns?.reduce((acc, curr) => {
        return acc + (curr.checked ? session.addonOption.price : 0);
      }, 0) || 0;

    return totalPrice > 0 ? formatPenceToPounds(totalPrice) : '';
  };

  const handleSelectAll = () => {
    if (!individualAddonForm.values.addOns) {
      return;
    }
    if (individualAddonForm.values.addOns.every((s) => s.checked)) {
      individualAddonForm.setValues({
        addOns: individualAddonForm.values.addOns.map((s) => ({ ...s, checked: false })),
      });
    } else {
      individualAddonForm.setValues({
        addOns: individualAddonForm.values.addOns.map((s) => ({ ...s, checked: true })),
      });
    }
  };

  const addOnSessionCheckboxes = (sessions?: AddOnSessionsList[]) => {
    if (!sessions) {
      return null;
    }

    return sessions.map((s, index) => {
      const isChecked = individualAddonForm.values.addOns?.[index].checked;

      const { onChange, ...rest } = individualAddonForm.getInputProps(`addOns.${index}.checked`, {
        type: 'checkbox',
      });

      const formattedDate = dayjs(s.date).format('ddd Do MMMM');

      return (
        <Fragment key={s.id}>
          <PebbleCheckbox
            classNames={{
              label: classNames({
                [classes.checkedLabel]: isChecked,
                [classes.uncheckedLabel]: !isChecked,
              }),
            }}
            label={
              hideEndTimes
                ? `${formattedDate} - ${s.startTime}`
                : `${formattedDate} - ${s.startTime} - ${s.endTime}`
            }
            {...rest}
            onChange={(e) => {
              onChange(e);
            }}
          />
        </Fragment>
      );
    });
  };

  // Create a deep copy of the addOns array
  const getUpdatedAddOnsFormCopy = (addOns: AddOnValue[]): AddOnValue[] => {
    return addOns.map((addOn, copyAddOnIndex) =>
      copyAddOnIndex !== addOnIndex
        ? addOn
        : {
            ...addOn,
            perSession: addOn.perSession.map((sessionAddOn, copySessionIndex) =>
              copySessionIndex !== sessionIndex
                ? sessionAddOn
                : {
                    ...sessionAddOn,
                    selectedSessions: individualAddonForm.values.addOns,
                  },
            ),
          },
    );
  };

  const addToOrder = async () => {
    addOnsForm.setFieldValue(
      `addOns.${addOnIndex}.perSession.${sessionIndex}.selectedSessions`,
      individualAddonForm.values.addOns,
    );

    // using updated addOnsForm copy as an input for the mutation
    const updatedAddOnsFormCopy = getUpdatedAddOnsFormCopy(addOnsForm.values.addOns);
    const inputData = getAddOnInput(updatedAddOnsFormCopy);

    await handleUpdateAddOns(inputData);
    closeSessionDetails();
    trackAction(Actions.ADD_ADDON_TO_ORDER);
  };

  return (
    <div className={classes.checkboxWrapper}>
      <div className={classes.contentWrapper}>
        <Divider color={theme.colors.gray[4]} orientation="horizontal" mb="lg" mt="sm" />
        <Text fw={700} size="14px" c={theme.colors.blue[8]} mb="sm">
          Apply Add-On to the dates you require
        </Text>
        <PebbleCheckbox
          data-testid="select all checkbox"
          onChange={handleSelectAll}
          classNames={{ label: classNames(classes.uncheckedLabel, classes.selectAllCheckbox) }}
          label="Select all"
          checked={individualAddonForm.values.addOns?.every(({ checked }) => checked)}
        />
        {addOnSessionCheckboxes(session.addonOption.availableSessions)}
      </div>
      <Flex
        className={classes.buttonWrapper}
        mt={isMobile ? 'lg' : '45px'}
        gap="md"
        direction={!isMobile ? 'row-reverse' : 'column'}
        justify="center"
      >
        <Button onClick={addToOrder} className={classes.primaryButton}>
          <span className={classes.addToOrder}>Add to order</span>
          {getPrice()}
        </Button>
        <Button
          onClick={() => {
            closeSessionDetails();
            trackAction(Actions.CANCEL_ADDON);
          }}
          className={classNames(classes.primaryButton, classes.outlineButton)}
        >
          Cancel
        </Button>
      </Flex>
    </div>
  );
};

export default SessionSelectContent;
