import { Affix, Badge, Button, Flex, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useBasket } from 'context/BasketContext';
import GetTicketsButton from 'components/GetTicketsButton/GetTicketsButton';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import BasketDrawerItem from './BasketDrawerItem';
import { SocialProofLabelEnum } from 'enums';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './BasketDrawer.module.scss';

interface IBasketDrawerProps {
  socialProofBadge: SocialProofLabelEnum | null;
  socialProofBannerTitleEventProperty?: () => string | null;
}

const BasketDrawer: React.FC<IBasketDrawerProps> = ({
  socialProofBadge,
  socialProofBannerTitleEventProperty,
}) => {
  const theme = useMantineTheme();
  const [showAll, setShowAll] = useState(false);

  const isLargeBreakpoint = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`, true);

  const { basketItems, setShowTicketTypesModal, activityDetails } = useBasket();

  const sortedBasketItems = useMemo(() => {
    if (!basketItems) return [];

    return [...basketItems].sort((a, b) => {
      // Returns 0 is they are the same date
      const dateComparison = (a.singleSessionDate || '').localeCompare(b.singleSessionDate || '');

      // If the dates are different, sort by date
      if (dateComparison !== 0) {
        return dateComparison;
      } else {
        // If the dates are the same, sort by start time
        return dayjs(a.startTime, 'HH:mm').isBefore(dayjs(b.startTime, 'HH:mm')) ? -1 : 1;
      }
    });
  }, [basketItems]);

  return (
    <>
      <Affix w="100%" className={classes.basketWrapper} data-testid="basket-drawer">
        <Flex align={'center'} maw={'1440px'} justify={'center'} mx={'auto'}>
          <Flex mr={8}>
            <Text fw={700} c={theme.colors.blue[6]} size={'md'}>
              Sessions{' '}
            </Text>
            <Badge
              color={theme.colors.blue[8]}
              radius="20px"
              ml={theme.spacing.xs}
              variant="filled"
            >
              {basketItems && basketItems.length}
            </Badge>
          </Flex>
          <Flex className={classes.sessionList} align={'center'}>
            {sortedBasketItems.length > 3
              ? sortedBasketItems
                  .slice(0, 3)
                  .map((product, index) => (
                    <BasketDrawerItem
                      product={product}
                      key={index}
                      maxItems={3}
                      hideEndTimes={activityDetails?.activity.hideEndTimes}
                    />
                  ))
              : sortedBasketItems.map((product, index) => (
                  <BasketDrawerItem
                    product={product}
                    key={index}
                    maxItems={3}
                    hideEndTimes={activityDetails?.activity.hideEndTimes}
                  />
                ))}
            {sortedBasketItems.length > 3 && !showAll && (
              <Button
                radius={6}
                onClick={() => {
                  setShowAll((prev) => !prev);
                  return trackAction(Actions.BASKET_MORE_SESSIONS);
                }}
              >
                <Text fw={400}>{`+${sortedBasketItems.length - 3} more sessions`}</Text>
                <Text fw={700} ml={8}>
                  view
                </Text>
              </Button>
            )}
          </Flex>
          <Stack align="center" gap={0}>
            <GetTicketsButton
              radius="xl"
              size="lg"
              onClick={() => {
                setShowTicketTypesModal(true);
                trackAction(Actions.GET_TICKETS, {
                  activityLocation: activityDetails?.activity.isOnline ? 'online' : 'in-person',
                  activityType: activityDetails?.activity.activityType,
                  ...(socialProofBadge && {
                    socialProofBadge,
                  }),
                  ...(socialProofBannerTitleEventProperty && {
                    socialProofBanner: socialProofBannerTitleEventProperty(),
                  }),
                });
              }}
              w={150}
              ml={8}
              className={classes.getTicketsCta}
            >
              Book now
            </GetTicketsButton>
            {showAll && (
              <Button
                onClick={() => setShowAll((prev) => !prev)}
                radius={6}
                mt={8}
                ml={8}
                className={classes.minimiseCta}
              >
                Minimise
              </Button>
            )}
          </Stack>
        </Flex>
        {showAll && (
          <Flex maw={'1440px'} mx={'auto'}>
            <Flex wrap={'wrap'} gap={8} mt={8} className={classes.sessionsOverflow}>
              {sortedBasketItems.slice(3, sortedBasketItems.length).map((product, index) => (
                <BasketDrawerItem
                  product={product}
                  key={index}
                  maxItems={isLargeBreakpoint ? 3 : 4}
                  hideEndTimes={activityDetails?.activity.hideEndTimes}
                />
              ))}
            </Flex>
          </Flex>
        )}
      </Affix>
    </>
  );
};

export default BasketDrawer;
