import { Button, ButtonProps } from '@mantine/core';
import { useContext } from 'react';
import { EmbedContext, EmbedContextType } from 'context/EmbedContext';
import { getIframeBtnStyles } from 'utils/iframeBtnStyles';
import classes from './GetTicketsButton.module.scss';
import { Ticket } from '@phosphor-icons/react';

interface IGetTicketsButton extends ButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  onClick(): void;
}

const GetTicketsButton: React.FC<IGetTicketsButton> = ({ children, radius = 'xs', ...rest }) => {
  const { iframeTheme } = useContext(EmbedContext) as EmbedContextType;
  const colours = getIframeBtnStyles(iframeTheme);

  return (
    <Button
      {...rest}
      className={classes.button}
      radius={radius}
      leftSection={<Ticket size={22} className={classes.ticketIcon} />}
      style={colours}
    >
      {children}
    </Button>
  );
};

export default GetTicketsButton;
