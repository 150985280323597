import { Group, Text, Title, UnstyledButton, Button, useMantineTheme, Stack } from '@mantine/core';
import { FC, useMemo, useReducer } from 'react';
import { Ticket, Plus, Check, Warning } from '@phosphor-icons/react';
import { formatPenceToPounds } from 'utils/formatPrice';
import { BasketApplyPromotion } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { PreCheckoutBasket, PreCheckoutBasketTicket } from 'interfaces';
import {
  PricingPolicyEnum,
  PromoErrorsEnum,
  SubscriptionTrialTypeEnum,
  ActivityTypeEnum,
} from 'enums';
import { trackAction, Actions } from 'utils/amplitude';
import { PebbleTextInput } from 'components/ui';
import classes from './SessionCost.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';
import getBasket from 'graphql/queries/getBasket';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export interface ISessionCostProps {
  userToken?: string;
  tickets: PreCheckoutBasketTicket[];
  numberOfSessionsAvailable: number;
  basket: PreCheckoutBasket;
  filteredTickets: PreCheckoutBasketTicket[];
}
type State = {
  discountCode: string;
  discountError: string;
  discountCodeValid: boolean;
  removingPromo: boolean;
  loading: boolean;
};

const initialState = {
  discountCode: '',
  discountError: '',
  discountCodeValid: false,
  removingPromo: false,
  loading: false,
};

type Action =
  | { type: 'VALID'; payload: { discount: number; finalAmount: number } }
  | { type: 'ERROR'; payload: string }
  | { type: 'CHANGE'; payload: string }
  | { type: 'REMOVE' }
  | { type: 'APPLYING' };

const VALID = 'VALID';
const ERROR = 'ERROR';
const CHANGE = 'CHANGE';
const REMOVE = 'REMOVE';
const APPLYING = 'APPLYING';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case VALID:
      return {
        ...state,
        removingPromo: false,
        loading: false,
      };
    case ERROR:
      return {
        ...state,
        discountError: action.payload,
        discountCodeValid: false,
        loading: false,
      };
    case CHANGE:
      return {
        ...state,
        discountError: '',
        discountCode: action.payload,
        discountCodeValid: action.payload.length > 2,
      };
    case REMOVE:
      return {
        ...initialState,
        removingPromo: true,
      };
    case APPLYING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

let changeTimeout: ReturnType<typeof setTimeout>;

type AddOnInfo = Record<
  string,
  {
    name: string;
    individualPrice: number;
    totalPrice: number;
    totalAmount: number;
  }
>;

const SessionCost: FC<ISessionCostProps> = ({
  userToken,
  tickets,
  basket,
  numberOfSessionsAvailable,
  filteredTickets,
}) => {
  const theme = useMantineTheme();
  const { promotion, finalAmount, originalAmount, discount, activity } = basket;

  const isSubscription = activity.activityType === ActivityTypeEnum.SUBSCRIPTION;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    discountCode: promotion?.code,
    discountCodeValid: Boolean(promotion?.code && discount),
  });
  const { discountCode, discountCodeValid, discountError, removingPromo, loading } = state;

  const baseVariables = useMemo(
    () => ({
      id: basket.id,
    }),
    [basket.id],
  );

  const addOnInfo = useMemo<AddOnInfo | null>(() => {
    if (!basket.addons || basket.addons?.length === 0) {
      return null;
    }
    const allAddOns = basket.addons.flatMap((addOn) => [
      ...(addOn.perSession ?? []),
      ...(addOn.perBooking ?? []),
    ]);
    return allAddOns.reduce((acc, curr) => {
      const quantity =
        'quantity' in curr
          ? curr.quantity
          : 'selectedSessions' in curr
          ? curr.selectedSessions.length
          : 0;
      if (quantity === 0) {
        return acc;
      }

      const key = curr.addonOption.id;
      const existing = acc[key];

      return {
        ...acc,
        [key]: {
          ...(existing
            ? {
                ...existing,
                totalAmount: existing.totalAmount + quantity,
                totalPrice: existing.totalPrice + existing.individualPrice * quantity,
              }
            : {
                name: curr.addonOption.name,
                individualPrice: curr.addonOption.price,
                totalAmount: quantity,
                totalPrice: curr.addonOption.price * quantity,
              }),
        },
      };
    }, {} as AddOnInfo);
  }, [basket.addons]);

  const totalCost = useMemo(() => {
    return finalAmount ?? originalAmount;
  }, [finalAmount, originalAmount]);

  const isFirstOfMonth = dayjs().date() === 1;

  const hasTrial = useMemo(() => {
    if (!isSubscription) {
      return false;
    }
    return tickets.some((ticket) => ticket.subscriptionTrialSelected);
  }, [isSubscription, tickets]);

  const trialPayment = useMemo(() => {
    if (!isSubscription) {
      return null;
    }

    if (hasTrial && activity.subscriptionTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL) {
      return 'Free';
    }
    if (hasTrial && activity.subscriptionTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL) {
      return formatPenceToPounds(activity.subscriptionTrialPrice || 0);
    }

    return null;
  }, [isSubscription, activity, hasTrial]);

  const trialEndDate = useMemo(() => {
    if (!isSubscription) {
      return undefined;
    }
    const timestamp = tickets[0]?.subscriptionTrialEndTimestamp;
    if (!timestamp) {
      return undefined;
    }
    const dayjsObj = dayjs.unix(timestamp);
    return {
      date: dayjsObj.format('DD/MM/YYYY'),
      month: dayjsObj.format('MMMM'),
    };
  }, [isSubscription, tickets]);

  const subscriptionStartDate = useMemo(() => {
    if (!isSubscription) {
      return undefined;
    }
    const startDateString = activity.subscriptionStart;
    if (!startDateString) {
      return undefined;
    }
    const dayjsObj = dayjs(startDateString);
    return {
      date: dayjsObj.format('DD/MM/YYYY'),
      month: dayjsObj.format('MMMM'),
    };
  }, [isSubscription, activity.subscriptionStart]);

  const getFirstFullPaymentMonth = () => {
    if (!trialEndDate?.date) return;

    const parsedDate = dayjs(trialEndDate.date, 'DD-MM-YYYY');

    return parsedDate.add(1, 'M').format('MMMM');
  };

  const costSummary = (ticketPrice: number, numberOfSessions: number): string => {
    const price = ticketPrice === 0 ? 'Free' : formatPenceToPounds(ticketPrice);
    if (isSubscription) {
      return `${price} / MONTH (first full payment taken on the 1st ${getFirstFullPaymentMonth()})`;
    }
    return `${price} x ${numberOfSessions} sessions`;
  };

  const [basketApplyPromotion] = useMutation(BasketApplyPromotion, {
    ...(userToken && {
      context: {
        headers: {
          Authorization: `${userToken}`,
        },
      },
    }),
    refetchQueries: [getBasket],
    onCompleted: (data) => {
      if (!data) {
        return;
      }
      const promoData = data.basketApplyPromotion;
      dispatch({
        type: VALID,
        payload: {
          discount: promoData?.discount,
          finalAmount: promoData?.finalAmount,
        },
      });
      if (discount) {
        trackAction(Actions.ACTIVITY_PROMO_CODE_VALID);
      }
      if (changeTimeout) {
        clearTimeout(changeTimeout);
      }
    },
    onError: (e) => {
      const message = Object.values<string>(PromoErrorsEnum).includes(e.message)
        ? e.message
        : 'Code not valid';
      dispatch({ type: ERROR, payload: message });
      console.log(e.message, 'error');
      trackAction(Actions.ACTIVITY_PROMO_CODE_INVALID);
    },
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: CHANGE, payload: e.target.value });
  };

  const removeCode = () => {
    dispatch({ type: REMOVE });

    basketApplyPromotion({
      variables: {
        input: {
          promotion: null,
          ...baseVariables,
        },
      },
    });
  };

  const addCode = () => {
    dispatch({ type: APPLYING });
    basketApplyPromotion({
      variables: {
        input: {
          promotion: discountCode,
          ...baseVariables,
        },
      },
    });
  };

  return (
    <section>
      {filteredTickets.map((ticket) => {
        const singleTicketPrice = ticket.ticketPrice;

        const numberOfTickets = tickets.filter(
          (selectedTicket) => selectedTicket.ticket === ticket.ticket,
        ).length;

        const numberOfSessions =
          ticket.sessions.length > 0 ? ticket.sessions.length : numberOfSessionsAvailable;

        const policy = ticket.pricingPolicy;

        const totalTicketPrice =
          policy === PricingPolicyEnum.BLOCK_FULL_PRICE || policy === PricingPolicyEnum.FREE
            ? ticket.amount * numberOfTickets
            : singleTicketPrice * numberOfTickets * numberOfSessions;

        return (
          <div className={classes.sessionCostSection} key={ticket.id}>
            <Stack gap={4}>
              <Group gap="xs" align="flex-start" wrap="nowrap">
                <Ticket
                  weight="fill"
                  size={18}
                  color={theme.colors.blue[8]}
                  className={classes.icon}
                />
                <Stack gap="4px">
                  <Text className={classes.priceHeader} fw={700}>
                    {numberOfTickets} x {ticket.ticketName}
                  </Text>

                  <Text size="xs" c={theme.colors.gray[6]}>
                    {costSummary(singleTicketPrice, numberOfSessions)}
                  </Text>
                </Stack>
              </Group>
            </Stack>
            {singleTicketPrice === 0 ? (
              <Text c={theme.colors.pink[6]} fw={700}>
                Free
              </Text>
            ) : (
              <Text className={classes.price} fw={700}>
                {formatPenceToPounds(isSubscription ? ticket.ticketPrice : totalTicketPrice)}
              </Text>
            )}
          </div>
        );
      })}

      {addOnInfo &&
        Object.entries(addOnInfo).map(([addOnID, info]) => {
          return (
            <div className={classes.sessionCostSection} key={addOnID}>
              <Stack gap={4}>
                <Group gap="xs" align="flex-start">
                  <Plus
                    weight="fill"
                    size={18}
                    color={theme.colors.blue[8]}
                    className={classes.icon}
                  />
                  <Stack gap="4px">
                    <Text className={classes.priceHeader} fw={700}>
                      {info.totalAmount} x {info.name}
                    </Text>
                    <Text size="xs" c={theme.colors.gray[6]}>
                      {info.individualPrice === 0
                        ? 'Free'
                        : formatPenceToPounds(info.individualPrice)}{' '}
                      x {info.totalAmount} add-ons
                    </Text>
                  </Stack>
                </Group>
              </Stack>
              <Text className={classes.price} fw={700}>
                {info.totalPrice === 0 ? (
                  <Text c={theme.colors.pink[6]} fw={700}>
                    Free
                  </Text>
                ) : (
                  formatPenceToPounds(info.totalPrice)
                )}
              </Text>
            </div>
          );
        })}
      {originalAmount > 0 && !isSubscription && (
        <>
          <div
            className={classNames(classes.sessionCostSection, {
              [classes.discountWrapper]: discount > 0,
            })}
            style={{ alignItems: 'flex-start' }}
          >
            {!discount && (
              <PebbleTextInput
                label="Discount code"
                placeholder="Insert your code here"
                size="md"
                onChange={handleChange}
                value={discountCode || ''}
                error={discountError}
                classNames={{
                  input: classes.discountInput,
                  label: classes.discountLabel,
                  error: classes.discountError,
                  section: classes.rightSection,
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    addCode();
                  }
                }}
              />
            )}

            {discount > 0 && discountCodeValid && (
              <>
                <Group>
                  <Text className={classes.discountCode} c={theme.colors.blue[8]} fw={700}>
                    {discountCode}
                  </Text>
                  <Check weight="bold" size={18} color={theme.colors.blue[6]} />
                  <UnstyledButton onClick={removeCode} className={classes.removeCode}>
                    Remove code
                  </UnstyledButton>
                </Group>
              </>
            )}
            {!removingPromo && (
              <Text className={classes.price} fw={700}>
                {discount > 0 ? '-' : ''}
                {formatPenceToPounds(discount)}
              </Text>
            )}
          </div>
          {discountCode && discount === 0 && (
            <Button onClick={addCode} disabled={!discountCode} loading={loading} mt="xs">
              Apply code
            </Button>
          )}
        </>
      )}

      {!isSubscription && (
        <div className={classNames(classes.sessionCostSection, classes.totalPrice)}>
          <Title order={5}>Total cost</Title>
          <Text
            className={classNames(classes.price, { [classes.freeTotal]: totalCost === 0 })}
            fw={700}
            data-testid="total-cost"
          >
            {totalCost === 0 ? 'Free' : formatPenceToPounds(totalCost)}
          </Text>
        </div>
      )}

      {isSubscription && hasTrial && (
        <>
          <Group className={classes.subsInfoBox} wrap="nowrap" align="flex-start" my="md" gap="sm">
            <Warning
              color={theme.colors.yellow[6]}
              className={classes.warningIcon}
              weight="fill"
              size={64}
            />
            <Text size="xs" c={theme.colors.gray[6]} fw={600}>
              <b>Due after trial ends:</b> Your trial ends on {trialEndDate?.date}. On this date you
              will be charged a prorated amount based on the remaining days of {trialEndDate?.month}
              . This is what you’ll pay so you can attend the activity in {trialEndDate?.month}{' '}
              before paying for the full subscription on the 1st of {getFirstFullPaymentMonth()}.
              You will then be charged on the 1st of each month moving forward.
            </Text>
          </Group>

          <div className={classNames(classes.sessionCostSection, classes.totalPrice)}>
            <Stack gap={2}>
              <Title order={5} mb={0}>
                Total to pay now
              </Title>
              <Text fw={600}>
                (for {activity.subscriptionTrialSessionCount} trial session
                {(activity.subscriptionTrialSessionCount || 0) > 1 ? 's' : ''})
              </Text>
            </Stack>
            <Text
              className={classNames(classes.price, {
                [classes.freeTotal]: totalCost === 0 || trialPayment === 'Free',
              })}
              fw={700}
              data-testid="total-cost"
            >
              {trialPayment}
            </Text>
          </div>
        </>
      )}
      {isSubscription && subscriptionStartDate && !hasTrial && (
        <Group className={classes.subsInfoBox} wrap="nowrap" align="flex-start" my="md" gap="sm">
          <Warning
            color={theme.colors.yellow[6]}
            className={classes.warningIcon}
            weight="fill"
            size={64}
          />
          <Stack gap="xs">
            <Text size="xs" c={theme.colors.gray[6]} fw={600}>
              This subscription does not begin until {subscriptionStartDate.date} - the first
              payment for this activity will be taken on that date.
            </Text>
            <Text size="xs" c={theme.colors.gray[6]} fw={600}>
              We’ll calculate the amount you need to pay on {subscriptionStartDate.date} for the
              remaining days of {subscriptionStartDate.month}. This is what you’ll pay so you can
              join the activity in {subscriptionStartDate.month}.
            </Text>
          </Stack>
        </Group>
      )}
      {isSubscription && !hasTrial && !isFirstOfMonth && !subscriptionStartDate && (
        <Group className={classes.subsInfoBox} wrap="nowrap" align="flex-start" my="md" gap="sm">
          <Warning
            color={theme.colors.yellow[6]}
            className={classes.warningIcon}
            weight="fill"
            size={64}
          />
          <Text size="xs" c={theme.colors.gray[6]} fw={600}>
            <b>Due now:</b> On the next page we’ll calculate the amount you need to pay now for the
            rest of this month. This is what you’ll pay now so you can join the activity this month.
          </Text>
        </Group>
      )}
    </section>
  );
};

export default SessionCost;
