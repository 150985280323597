import { Flex, Text, List, useMantineTheme } from '@mantine/core';
import { Product } from 'types';
import { useBasket } from 'context/BasketContext';
import dayjs from 'dayjs';
import classes from './BasketPopup.module.scss';
import { X } from '@phosphor-icons/react';

interface IBasketSessionsListItemProps {
  product: Product;
  hideEndTimes?: boolean;
}

const BasketSessionsListItem: React.FC<IBasketSessionsListItemProps> = ({
  product,
  hideEndTimes = false,
}) => {
  const theme = useMantineTheme();

  const { removeFromBasket } = useBasket();

  const formattedDate = dayjs(product.singleSessionDate).format('dddd Do MMM');

  return (
    <List.Item
      classNames={{
        itemWrapper: classes.listItemWrapper,
        itemLabel: classes.listItemLabel,
      }}
    >
      <Flex justify="space-between" align="center" style={{ width: '100%' }}>
        <Text c={theme.colors.blue[8]}>{formattedDate}</Text>
        <Flex align="center">
          <Text fw="bold">
            {hideEndTimes ? product.startTime : `${product.startTime} - ${product.endTime}`}
          </Text>
          <div
            style={{
              marginLeft: theme.spacing.md,
              display: 'grid',
              placeItems: 'center',
            }}
            onClick={() => removeFromBasket(product.checkoutValue)}
          >
            <X size={18} weight="bold" />
          </div>
        </Flex>
      </Flex>
    </List.Item>
  );
};

export default BasketSessionsListItem;
