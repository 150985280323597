import {
  Badge,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import { IconActivity } from '@icons';
import DesktopSuccessfulBooking from '../../../../public/desktop_confirmation.png';
import MobileSuccessfulBooking from '../../../../public/mobile_confirmation.png';
import { Actions, trackAction } from 'utils/amplitude';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { AnonymisedActivityBookingSchemaType, AnonymisedBookingTicketSessionsType } from 'types';
import { TicketTypeEnum, ActivityBookingStatusEnum, ActivityBookingTypeEnum } from 'enums';
import ReviewSection from 'components/ReviewSection/ReviewSection';
import { useMediaQuery } from '@mantine/hooks';
import { calculateAddons, calculateTickets } from './Success.utils';
import { useEffect, useMemo } from 'react';
import classes from '../index.module.scss';
import { formatPenceToPounds } from 'utils/formatPrice';
import classNames from 'classnames';
import {
  CalendarBlank,
  Clock,
  MapPin,
  PlusSquare,
  Ticket,
  VideoCamera,
} from '@phosphor-icons/react';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Array<any>;
  }
}

interface IStripeSuccess {
  booking: AnonymisedActivityBookingSchemaType;
  userToken: string | null;
  reviewDisabled: boolean;
}

export const Success: React.FC<IStripeSuccess> = ({ userToken, reviewDisabled, booking }) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: 465px)`, true);

  const { isOnline, location, supplier, subscriptionStart, hideEndTimes } = booking.activity;

  const isIndividual = booking.bookedTickets[0]?.ticket.ticketType === TicketTypeEnum.INDIVIDUAL;

  const uniqueSessions = useMemo<AnonymisedBookingTicketSessionsType[]>(() => {
    if (booking.bookedTickets[0]?.sessions) {
      return booking.bookedTickets[0]?.sessions.filter(
        (session, index, self) =>
          index ===
          self.findIndex(
            (s) => s.sessionStart === session.sessionStart && s.sessionEnd === session.sessionEnd,
          ),
      );
    } else {
      return [];
    }
  }, [booking.bookedTickets]);

  const hasMultipleSessions = uniqueSessions.length > 1;

  const isMultiSingleSession = isIndividual && hasMultipleSessions;

  const multiSingleSessionsString = uniqueSessions
    .slice(0, 3)
    .map((s) => {
      const date = dayjs(s.sessionStart).utc().format('DD MMM YYYY');
      const startTime = dayjs(s.sessionStart).utc().format('HH:mm');
      const endTime = dayjs(s.sessionEnd).utc().format('HH:mm');

      return hideEndTimes ? `${date} ${startTime}` : `${date} ${startTime} - ${endTime}`;
    })
    .join(',');

  const multiSingleSessions = isMultiSingleSession ? multiSingleSessionsString : '';

  const multiSingleSessionsQuantity = isMultiSingleSession
    ? uniqueSessions.length.toString() || String(0)
    : '';

  const isSubscription = booking.bookingType === ActivityBookingTypeEnum.SUBSCRIPTION;

  const isSubsTrialSelected = booking.bookedTickets[0]?.subscriptionTrialSelected;

  const trialCost = booking.bookedTickets[0]?.subscriptionTrialPrice;

  const trialLength = booking.bookedTickets[0]?.subscriptionTrialSessionCount;

  const isFutureSub = Boolean(subscriptionStart);

  const paymentCompleted =
    booking.status === ActivityBookingStatusEnum.CONFIRMED &&
    (!isSubscription || !isSubsTrialSelected);

  const paymentPending =
    booking.status === ActivityBookingStatusEnum.CONFIRMED && isSubsTrialSelected;

  const firstStartDate = uniqueSessions?.[0]?.sessionStart;
  const firstEndDate = uniqueSessions?.[0]?.sessionEnd;

  const formattedNextSessionDate = useMemo(() => {
    if (firstStartDate) {
      return dayjs(firstStartDate).utc().format('Do MMMM YYYY');
    }
    if (booking.bookedTickets[0]?.firstSessionToBe) {
      return dayjs(booking.bookedTickets[0]?.firstSessionToBe).utc().format('Do MMMM YYYY');
    }
    return '';
  }, [firstStartDate, booking.bookedTickets]);

  const activityDateRange = () => {
    const lastSession = booking.bookedTickets[0].sessions.length - 1;
    const startDate = dayjs(firstStartDate).utc().format('Do MMM');
    const endDate = dayjs(booking.bookedTickets[0].sessions[lastSession].sessionStart)
      .utc()
      .format('Do MMM');

    if (isIndividual) {
      return dayjs(firstStartDate).utc().format('Do MMMM');
    }

    return `${startDate} - ${endDate}`;
  };

  const ticketsString = calculateTickets(booking.bookedTickets);

  const addOnsString = calculateAddons(booking.bookedTickets);

  const localStorageFullName = useMemo(() => {
    if (typeof window === 'undefined') {
      return '';
    }
    return window && window.sessionStorage.getItem('fullname');
  }, []);
  const localStorageEmail = useMemo(() => {
    if (typeof window === 'undefined') {
      return '';
    }
    return window && window.sessionStorage.getItem('email');
  }, []);

  const startTime = dayjs(firstStartDate).utc().format('HH:mm');
  const endTime = dayjs(firstEndDate).utc().format('HH:mm');

  const activityTime = hideEndTimes ? startTime : `${startTime} - ${endTime}`;

  const timesVary = () => {
    const allMatchCoreTime = booking.bookedTickets[0].sessions.every(
      (date) =>
        dayjs(date.sessionStart).utc().format('HH:mm') ===
          dayjs(firstStartDate).utc().format('HH:mm') &&
        dayjs(date.sessionEnd).utc().format('HH:mm') === dayjs(firstEndDate).utc().format('HH:mm'),
    );

    return !allMatchCoreTime;
  };

  const opts = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } as Intl.NumberFormatOptions;

  useEffect(() => {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: 'order_complete',
        order_id: booking.id,
        order_value: booking.finalAmount,
        order_currency: 'GBP',
        ...(booking.promotion?.code && {
          order_promotion_code: booking.promotion.code,
        }),
      });
    }
  }, [booking.id, booking.finalAmount, booking.promotion?.code]);

  useEffect(() => {
    const reqBody = {
      amount: booking?.finalAmount,
      id: booking?.id,
      promoCode: booking?.promotion?.code,
    };
    fetch('/api/conversion_tracking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(reqBody),
    });
  }, [booking.finalAmount, booking.id, booking.promotion]);

  return (
    <>
      <div>
        <Image
          src={isMobile ? MobileSuccessfulBooking : DesktopSuccessfulBooking}
          alt="parent and child on shaped background"
        />
        <div className={classes.wrapper}>
          <Title order={3} className={classes.header} style={{ textAlign: 'center' }}>
            Booking confirmed!
          </Title>
          <Text className={classes.confirmationText} size="md" style={{ textAlign: 'center' }}>
            We've sent you confirmation
          </Text>
          {!reviewDisabled && localStorageFullName && localStorageEmail && (
            <ReviewSection
              activityId={booking.activity.id}
              fullName={JSON.parse(localStorageFullName)}
              email={JSON.parse(localStorageEmail)}
            />
          )}
          <Card className={classes.card}>
            <Grid columns={12} align="center">
              <Grid.Col span={5} py="sm">
                <Flex align={'center'}>
                  <IconActivity size={24} color={theme.colors.gray[6]} />
                  <Text fw={600} c={theme.colors.gray[6]}>
                    Activity
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                {booking.activity.name}
              </Grid.Col>
              <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
              {isSubscription ? (
                <>
                  <Grid.Col span={5} py="sm">
                    <Flex align="center">
                      <CalendarBlank size={24} color={theme.colors.gray[6]} />
                      <Text fw={600} c={theme.colors.gray[6]}>
                        Next date
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                    {formattedNextSessionDate}
                    <br />
                    {isSubsTrialSelected && !trialCost && (
                      <Text className={classes.freeSession} c={theme.colors.blue[5]}>
                        {trialLength === 1
                          ? 'First session free'
                          : `First ${trialLength} sessions free`}
                      </Text>
                    )}
                  </Grid.Col>
                  <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
                  <Grid.Col span={5} py="sm">
                    <Flex align="center">
                      <Clock size={24} strokeWidth={1.75} color={theme.colors.gray[6]} />
                      <Text fw={600} c={theme.colors.gray[6]}>
                        Session
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                    {activityTime && (
                      <Text c={theme.colors.blue[8]} fw={700}>
                        {booking.bookedTickets[0].subscriptionDayOfWeek}s {activityTime}
                      </Text>
                    )}
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col
                    span={4}
                    py="sm"
                    className={classNames({ [classes.dateIcon]: isMultiSingleSession })}
                  >
                    <Flex align="center">
                      <CalendarBlank size={24} color={theme.colors.gray[6]} />
                      <Text fw={600} c={theme.colors.gray[6]}>
                        Date
                      </Text>
                    </Flex>
                  </Grid.Col>
                  {isMultiSingleSession && (
                    <Grid.Col span={8} py="sm">
                      {(multiSingleSessions.split(',') || []).map((_session, index) => {
                        return (
                          <Text
                            className={classes.bookingDetailsInfo}
                            c={theme.colors.blue[8]}
                            key={index}
                          >
                            {_session}
                          </Text>
                        );
                      })}
                      {Number(multiSingleSessionsQuantity) - 3 > 0 && (
                        <Text style={{ textAlign: 'right' }} fw={700} c={theme.colors.blue[8]}>
                          + {Number(multiSingleSessionsQuantity) - 3} more
                        </Text>
                      )}
                    </Grid.Col>
                  )}
                  {!isMultiSingleSession && (
                    <Grid.Col span={8} py="sm" className={classes.bookingDetailsInfo}>
                      {activityDateRange()} <br />
                      {activityTime} <br />
                      {timesVary() && (
                        <Badge
                          classNames={{ label: classes.timeVaryLabel, root: classes.timeVaryRoot }}
                          variant="filled"
                          color={theme.colors.blue[1]}
                          rightSection={<Clock color={theme.colors.blue[8]} size={15} />}
                        >
                          Times vary
                        </Badge>
                      )}
                    </Grid.Col>
                  )}
                </>
              )}
              <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
              <Grid.Col span={5} py="sm">
                <Flex align="center">
                  <Ticket size={24} color={theme.colors.gray[6]} />
                  <Text c={theme.colors.gray[6]} fw={600}>
                    Tickets
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                {ticketsString && (
                  <Text className={classes.ticketDetails} c={theme.colors.blue[8]} fw={700}>
                    {ticketsString}
                  </Text>
                )}
              </Grid.Col>

              {addOnsString && (
                <>
                  <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
                  <Grid.Col span={5} py="sm">
                    <Flex align="center">
                      <PlusSquare size={24} color={theme.colors.gray[6]} />
                      <Text c={theme.colors.gray[6]} fw={600}>
                        Add-On
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                    <Text className={classes.ticketDetails} c={theme.colors.blue[8]} fw={700}>
                      {addOnsString}
                    </Text>
                  </Grid.Col>
                </>
              )}

              <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
              {isOnline ? (
                <>
                  <Grid.Col span={5} py="sm">
                    <Flex align="center">
                      <VideoCamera size={24} color={theme.colors.gray[6]} />
                      <Text c={theme.colors.gray[6]} fw={600}>
                        Location
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                    <Text c={theme.colors.blue[5]} fw={700}>
                      ONLINE
                    </Text>
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col span={5} py="sm">
                    <Flex align="center">
                      <MapPin size={24} color={theme.colors.gray[6]} />
                      <Text c={theme.colors.gray[6]} fw={600}>
                        Location
                      </Text>
                    </Flex>
                  </Grid.Col>
                  {location && (
                    <Grid.Col span={7} py="sm" fw={700} className={classes.bookingDetailsInfo}>
                      {location.addressLine1} {location.addressLine2}, {location.city},{' '}
                      {location.postCode}{' '}
                    </Grid.Col>
                  )}
                </>
              )}
            </Grid>
          </Card>
          <p>
            {supplier.name} may be in touch via email with the waiver and relevant documentation if
            required.
          </p>
          {isSubscription && isSubsTrialSelected && (
            <section>
              <Text component="h4">Payment</Text>
              <div className={classes.priceSection}>
                {trialCost ? (
                  <>
                    <Group>
                      <Text component="h4">Total cost</Text>
                      <Badge className={classes.badge}>Paid</Badge>
                    </Group>
                    <span className={classes.price}>{formatPenceToPounds(trialCost)}</span>
                  </>
                ) : (
                  <>
                    <Text component="h4">Total cost</Text>
                    <span className={classNames(classes.price, classes.free)}>Free</span>
                  </>
                )}
              </div>
            </section>
          )}
          {isSubscription && !isSubsTrialSelected && !isFutureSub && (
            <section>
              <div className={classes.priceSection}>
                <Text component="h4">Total monthly cost</Text>
                <span className={classes.price}>
                  {Number(booking.finalAmount).toLocaleString('en-GB', opts)}
                </span>
              </div>
            </section>
          )}
          {isSubscription && !isSubsTrialSelected && isFutureSub && (
            <section>
              <div className={classes.priceSection}>
                <Text component="h4">Total monthly cost</Text>
                <span className={classes.price}>
                  {formatPenceToPounds(booking.bookedTickets[0].ticketPriceAtBooking)}
                </span>
              </div>
            </section>
          )}

          {!isSubscription && booking.finalAmount !== '0.00' && (
            <section>
              {<Text component="h4">Payment</Text>}
              <div className={classes.priceSection}>
                <Group>
                  {paymentPending && <Badge className={classes.badge}>Pending</Badge>}{' '}
                  {paymentCompleted && <Badge className={classes.badge}>Paid</Badge>}{' '}
                  <span className={classes.price}>
                    {Number(booking.finalAmount).toLocaleString('en-GB', opts)}
                  </span>
                </Group>
              </div>
            </section>
          )}

          {/* 100% discounted basket of Free activity */}
          {!isSubscription && booking.finalAmount === '0.00' && (
            <section>
              <div className={classes.priceSection}>
                <Text component="h4">Total cost</Text>
                <span className={classNames(classes.price, classes.free)}>Free</span>
              </div>
            </section>
          )}

          <section>
            <Text component="h5">Cancellation policy</Text>
            {!isSubscription && (
              <p>
                If you cancel your booking you might not receive a refund. Please contact the
                supplier or revisit the terms & conditions for further information.
              </p>
            )}
            {isSubscription && !isSubsTrialSelected && (
              <p>
                If you do not wish to continue, then you will need to contact the provider to
                request cancellation. Cancel via provider anytime (subject to provider T&C's).
              </p>
            )}
            {isSubscription && isSubsTrialSelected && (
              <>
                <Text mb="sm">
                  The subscription begins after the trial period unless cancelled at your request.
                </Text>
                <Text mb="sm">
                  After your trial ends we'll calculate the amount you need to pay for the remaining
                  days in the month. We'll charge this immediately after the trial ends.
                </Text>
                <Text mb="sm">
                  If you do not wish to continue, then you will need to contact the provider to
                  request cancellation after the trial period has ended. Cancel via provider anytime
                  (subject to provider T&C's).
                </Text>
              </>
            )}
          </section>
          <section className={classes.footer}>
            <Link href={`/supplier/${supplier.slug}`} passHref legacyBehavior>
              <Button
                component="a"
                radius="lg"
                size="md"
                fullWidth
                className={classes.button}
                onClick={() => {
                  trackAction(Actions.MARKETPLACE_EXPLORE_MORE_ACTIVITIES);
                }}
              >
                Explore More From This Provider
              </Button>
            </Link>
            {userToken ? (
              <Button
                mt="sm"
                component="a"
                href={process.env.NEXT_PUBLIC_PEBBLE_APP_ENDPOINT}
                radius="lg"
                size="md"
                fullWidth
                variant="outline"
                onClick={() => {
                  trackAction(Actions.MARKETPLACE_GO_TO_HOMEPAGE);
                }}
                className={classes.outlineButton}
              >
                Go to Homepage
              </Button>
            ) : (
              <p>
                Want to checkout faster next time? <br />{' '}
                <a
                  href={`${process.env.NEXT_PUBLIC_PEBBLE_APP_ENDPOINT}register`}
                  className={classes.secondaryLink}
                  onClick={() => {
                    trackAction(Actions.MARKETPLACE_CONFIRMATION_ACCOUNT_CREATE);
                  }}
                >
                  Create an account
                </a>
              </p>
            )}
          </section>
        </div>
        <div className={classes.thankyouSection}>
          <Text className={classes.thankyouText} c={theme.colors.blue[8]}>
            THANK YOU!
          </Text>
        </div>
      </div>
    </>
  );
};
